export type CautionError<T> = {
  messageOf: {
    [P in keyof T]+?: string;
  };
};

export const initialCautionError = <T>(): CautionError<T> => ({
  messageOf: {},
});

export type CautionedData<T, S extends T = T> =
  | {
      ok: true;
      cautionedData: S;
    }
  | {
      ok: false;
      cautionError: CautionError<T>;
    };