import {
  ParentDetailSchema,
  SRFCreateSchema,
  D002Schema,
  TrainingH002Schema,
  ParentChildrenCreateSchema,
  TrainingH003Schema,
  FCStaffAllInfoSchema,
  EventV003Schema,
  HeadquarterUserR002Schema,
  AuditDocM002Schema,
  ChildD008Schema,
  G002ChatMessageCreateSchema,
  FCFacilityF002Schema,
  J002Schema,
  E002Schema,
  D009Schema,
  D004Schema,
  P002Schema,
  L004Schema,
  L008Schema,
  N002Schema,
  GeneralForListSchema,
  FCFacilitiesSchema,
  ChildForListSchema,
  E001Schema,
  ChildSchema,
  ProgramItemForSelectSchema,
  TrainingH001Schema,
  P001Schema,
  AuditDocM001Schema,
  W001Schema,
  L001Schema,
  N001Schema,
  SRFSchema,
  ParentForParentListSchema,
  G002Schema,
  J001SendSchema,
  J001ReceiveSchema,
  D003HealthRecordSchema,
  N003Schema,
  G001Schema,
  D010Schema,
  HolidayPlannerApi,
  TrainingsApi,
  K003PutRequestSchema,
} from "../generated";
import {
  headquartersApi,
  makeHeaderOptionFromToken,
  parentsApi,
  fcFacilitiesApi,
  childrenApi,
  fcStaffsApi,
  programItemsApi,
  notificationsApi,
  eventsApi,
  equipmentsApi,
  scheduleApi,
  generalsApi,
  trainingsApi,
  auditDocsApi,
  chatMessagesApi,
  signatureRequestsApi,
  templatesApi,
  uploadsApi,
  testsApi,
  batchApi,
  contactApi,
  holidayPlannerApi,
} from "../helper/api.helper";
import { AuthState } from "../redux/reducers/auth.reducer";
import {
  SubscriptionContentModel,
  SubscriptionContentUpdateModel,
} from "../models/subscriptionContents.model";
import { RECORD_LIMIT, TAKE_LIMIT_M, TAKE_LIMIT_S, TAKE_LIMIT_SS, TAKE_LIMIT_XS } from "../constants";
import { initialChatDataModel } from "../models/chat.model";
import { AxiosResponse } from "axios";

// 設定（本部スタッフ）
export const changeHeadquarterName = (
  newFamilyName: string,
  newGivenName: string,
  newFamilyNameKana: string,
  newGivenNameKana: string,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return headquartersApi.changeHeadquarterName(
    newFamilyName,
    newGivenName,
    newFamilyNameKana,
    newGivenNameKana,
    headers
  );
};

export const changeHeadquarterEmail = (email: string, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return headquartersApi.changeHeadquarterEmail(email, headers);
};

export const changeHeadquarterPassword = (
  password: string,
  newPassword: string,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return headquartersApi.changeHeadquarterPassword(
    password,
    newPassword,
    headers
  );
};

export const updateHeadquarterUserImage = (
  file: File,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return headquartersApi.updateHeadquarterUserImage(file, headers);
};

// 設定（FCスタッフ）
export const changeStaffName = (
  newFamilyName: string,
  newGivenName: string,
  newFamilyNameKana: string,
  newGivenNameKana: string,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.changeStaffName(
    newFamilyName,
    newGivenName,
    newFamilyNameKana,
    newGivenNameKana,
    headers
  );
};

export const changeStaffEmail = (email: string, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.changeStaffEmail(email, headers);
};

export const changeStaffPassword = (
  password: string,
  newPassword: string,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.changeStaffPassword(password, newPassword, headers);
};

export const changeStaffImage = (file: File, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.changeStaffImage(file, headers);
};

//保護者
export const fetchParent = (parentId: number, fcId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return parentsApi.getParent(parentId, fcId, headers);
};

export const fetchC001 = (authState: AuthState, fcId: number, endFlg: boolean, parentName: string, childName: string, skip: number, limit: number) => {
  const headers = makeHeaderOptionFromToken(authState);
  return parentsApi.getParentsForParentList(fcId, endFlg, parentName, childName, skip, limit, headers);
};

export const fetchC001Count = (authState: AuthState, fcId: number, endFlg: boolean, parentName: string, childName: string) => {
  const headers = makeHeaderOptionFromToken(authState);
  return parentsApi.getParentsForParentListCount(fcId, endFlg, parentName, childName, headers);
};

export const putParent = (authState: AuthState, data: ParentDetailSchema) => {
  const header = makeHeaderOptionFromToken(authState);
  return parentsApi.putParent(data, header);
};

export const putParentImage = (
  id: number,
  file: File,
  authState: AuthState
) => {
  const header = makeHeaderOptionFromToken(authState);
  return parentsApi.putParentImage(id, file, header);
};

export const deleteParent = (id: number, authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return parentsApi.deleteParent(id, header);
};

export const createParentChildren = (
  authState: AuthState,
  data: ParentChildrenCreateSchema
) => {
  const header = makeHeaderOptionFromToken(authState);
  return parentsApi.createParentChildren(data, header);
};

export const fetchD002 = (childId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getD002(childId, headers);
};

export const putD002 = (authState: AuthState, data: D002Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.putD002(data, header);
};

export const putChildImage = (id: number, file: File, authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.putChildImage(id, file, header);
};

export const deleteChild = (id: number, authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.deleteChild(id, header);
};

export const fetchD003 = (childId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getD003(childId, headers);
};

export const fetchD003List = (childId: number, authState: AuthState, skip: number, limit: number) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getD003List(childId, skip, limit, headers);
};

export const fetchD003ListCount = (childId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getD003ListCount(childId, headers);
};

export const fetchHealthRecord = (childId: number, healthRecordId: number | undefined, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getD004(childId, healthRecordId, headers);
};

export const putHealthRecord = (authState: AuthState, data: D004Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.putD004(data, header);
};

export const deleteHealthRecord = (id: number, authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.deleteD004(id, header);
};

// 個別支援計画
export const fetchE001 = (authState: AuthState, facilityId: number, begin_date: string | undefined, end_date: string | undefined, fullName: string | undefined, skip: number, limit: number) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getE001(facilityId, begin_date, end_date, fullName, skip, limit, headers);
};

export const fetchE001Count = (authState: AuthState, facilityId: number, begin_date: string | undefined, end_date: string | undefined, fullName: string | undefined) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getE001Count(facilityId, begin_date, end_date, fullName, headers);
};

export const fetchE001ByChildId = (
  authState: AuthState,
  childId: number,
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getE001ByChild(childId, headers);
};

export const fetchIsp = (
  authState: AuthState,
  childId: number,
  individualSupportPlanId: number,
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getE002(childId, individualSupportPlanId, headers);
};

export const putIsp = (
  data: E002Schema,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.putE002(data, headers);
};

export const putDocs = async (
  id: number,
  fileInfo: [{
    fileType: number,
    file: File,
  }],
  authState: AuthState): Promise<void> => {
  const header = makeHeaderOptionFromToken(authState);
  const putRequests = fileInfo.map((x) => {
    return childrenApi.putE002Doc(id, x.fileType, x.file, header);
  });

  try {
    await Promise.all(putRequests);
  } catch (error) {
    throw error;
  }
};

export const deleteIsp = (individualSupportPlanId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.deleteE002(individualSupportPlanId, headers);
};

export const RequestSign = (individualSupportPlanId: number, fileType: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.e002SignatureNotify(individualSupportPlanId, fileType, headers);
};

// アセスメントシート
export const fetchAssessments = (
  authState: AuthState,
  childId: number,
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getD006(childId, headers);
};

export const fetchAssessment = (
  authState: AuthState,
  childId: number,
  assessmentId: number,
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getD009(childId, assessmentId, headers);
};

export const putAssessment = (
  data: D009Schema,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.putD009(data, headers);
};

export const putDoc = async (
  assessmentId: number,
  file: File,
  authState: AuthState
) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.putD009Doc(assessmentId, file, header);
};

export const deleteAssessment = (assessmentId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.deleteD009(assessmentId, headers);
};

// FC
export const fetchFcFacilities = async (authState: AuthState, search: string) => {
  const headers = makeHeaderOptionFromToken(authState);
  let list: FCFacilitiesSchema[] = [];
  while (true) {
    const take_limit = TAKE_LIMIT_M;
    const res = await fcFacilitiesApi.getFcFacilities(search, list.length, take_limit, headers);
    list = list.concat(res.data);
    if (res.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

export const fetchFCStaff = (staffId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.getFcStaff(staffId, headers);
};

export const fetchFcFacilityAllInfo = (fcStaffId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getF002(fcStaffId, headers);
};

export const putFcFacilityAllInfo = (
  data: FCFacilityF002Schema,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.putF002(data, headers);
};

export const deleteFcFacilityAllInfo = (
  fcFacilityId: number,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.deleteF002(fcFacilityId, headers);
};


export const getFacilityHoliday = (
  fcFacilityId: number,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getFcFacilityHoliday(fcFacilityId, headers);
};

//スタッフ
export const fetchStaff = (fcStaffId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.getFcStaff(fcStaffId, headers);
};

export const putStaff = (authState: AuthState, data: FCStaffAllInfoSchema) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.putFcStaff(data, header);
};

export const putStaffImage = (id: number, file: File, authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.putFcStaffImage(id, file, header);
};

export const deleteStaff = (id: number, authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.deleteFcStaff(id, header);
};

// 本部スタッフ
export const fetchHeadQuarterUserForList = (authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return headquartersApi.getHeadquarterUserR001(headers);
};

export const fetchHeadQuarterUser = (id: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return headquartersApi.getHeadquarterUserR002(id, headers);
};

export const putHeadQuarterUser = (
  authState: AuthState,
  data: HeadquarterUserR002Schema
) => {
  const header = makeHeaderOptionFromToken(authState);
  return headquartersApi.putHeadquarterUserR002(data, header);
};

export const putHeadQuarterUserImage = (
  id: number,
  file: File,
  authState: AuthState
) => {
  const header = makeHeaderOptionFromToken(authState);
  return headquartersApi.putHeadquarterUserImage(id, file, header);
};

export const deleteHeadQuarterUser = (id: number, authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return headquartersApi.deleteHeadquarterUser(id, header);
};

export const fetchProgramForSelect = async (authState: AuthState, reflections: number[]) => {
  const headers = makeHeaderOptionFromToken(authState);
  let list: ProgramItemForSelectSchema[] = [];
  while (true) {
    const take_limit = TAKE_LIMIT_SS;
    const ret = await programItemsApi.getProgramItemsForSelect(reflections, list.length, take_limit, headers);
    list = list.concat(ret.data);
    if (ret.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

// アップロード
export const fetchN001 = async (category: number | undefined, authState: AuthState, skip: number, limit: number) => {
  const headers = makeHeaderOptionFromToken(authState);
  let list: N001Schema[] = [];
  while (list.length < limit) {
    const take_limit = Math.min(TAKE_LIMIT_XS, limit - list.length);
    const ret = await uploadsApi.getN001(category, skip + list.length, take_limit, headers);
    list = list.concat(ret.data);
    if (ret.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

export const fetchN001Count = (category: number | undefined, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return uploadsApi.getN001Count(category, headers);
};

export const fetchUploadData = (
  uploadId: number | undefined,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return uploadsApi.getN002(uploadId, headers);
};

export const putUploadData = (
  data: N002Schema,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return uploadsApi.putN002(data, headers);
};

export const putUploadDataFile = (
  uploadId: number,
  file: any,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return uploadsApi.putN002File(uploadId, file, headers);
};

export const deleteUploadData = (
  uploadId: number,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return uploadsApi.deleteN002(uploadId, headers);
};

export const fetchN003 = (category: number | undefined, title: string | undefined, authState: AuthState, skip: number, limit: number) => {
  const headers = makeHeaderOptionFromToken(authState);
  return uploadsApi.getN003(category, title, skip, limit, headers);
};

export const fetchN003Count = (category: number | undefined, title: string | undefined, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return uploadsApi.getN003Count(category, title, headers);
};

// 備品
export const fetchEquipments = async (
  serial: string,
  name: string,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  let list: L001Schema[] = [];
  while (true) {
    const take_limit = TAKE_LIMIT_S;
    const ret = await equipmentsApi.getL001(serial, name, list.length, take_limit, headers);
    list = list.concat(ret.data);
    if (ret.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

export const fetchL001 = (serial: string, name: string, skip: number, limit: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.getL001(serial, name, skip, limit, headers);
}

export const fetchL001Count = (serial: string, name: string, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.getL001Count(serial, name, headers);
}

export const fetchEquipment = (equipmentId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.getL004(equipmentId, headers);
};

export const putEquipment = (data: L004Schema, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.putL004(data, headers);
};

export const deleteEquipment = (
  equipmentId: number,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.deleteL004(equipmentId, headers);
};

export const putOrders = (order: L008Schema, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.putL008(order, headers);
};

export const fetchOrdersInCart = (orderIds: number[], authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.getL008(orderIds, headers);
};

export const fetchL002 = (
  status: number,
  fcId: number,
  beginDate: string | undefined,
  endDate: string | undefined,
  authState: AuthState,
  skip: number,
  limit: number) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.getL002(status, fcId, beginDate, endDate, skip, limit, headers);
};

export const fetchL002Count = (
  status: number,
  fcId: number,
  beginDate: string | undefined,
  endDate: string | undefined,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.getL002Count(status, fcId, beginDate, endDate, headers);
};

export const fetchOrder = (
  orderId: number,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.getL003(orderId, headers);
};

export const putChangeStatus = (orderDetailId: number, deliveryStatus: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return equipmentsApi.updateOrderDetailStatus(orderDetailId, deliveryStatus, headers);
};

export const putEquipmentImageAndFile = (
  equipmentId: number,
  image: any,
  file: any,
  authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  if (image && file) {
    const imageUploadPromise = equipmentsApi.putL004Image(equipmentId, image, headers);
    const fileUploadPromise = equipmentsApi.putL004OrderForm(equipmentId, file, headers);
    return Promise.all([imageUploadPromise, fileUploadPromise]);
  } else if (image) {
    return equipmentsApi.putL004Image(equipmentId, image, headers);
  } else {
    return equipmentsApi.putL004OrderForm(equipmentId, file, headers);
  }
};

// チャット
export const getG001ForHeadquarterUser = async (
  facilityId: number,
  rank: number,
  name: string,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  let list: G001Schema[] = [];
  while (list.length < RECORD_LIMIT) {
    const take_limit = Math.min(TAKE_LIMIT_S, RECORD_LIMIT - list.length);
    const ret = await chatMessagesApi.getG001ForHeadquarterUser(facilityId, rank, name, list.length, take_limit, headers);
    list = list.concat(ret.data);
    if (ret.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

export const getG001ForStaff = async (
  userType: string,
  name: string,
  authState: AuthState
) => {
  const headers = makeHeaderOptionFromToken(authState);
  let list: G001Schema[] = [];
  while (list.length < RECORD_LIMIT) {
    const take_limit = Math.min(TAKE_LIMIT_S, RECORD_LIMIT - list.length);
    const ret = await chatMessagesApi.getG001ForFcStaff(userType, name, list.length, take_limit, headers);
    list = list.concat(ret.data);
    if (ret.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

export const getG002 = async (
  user_type: string,
  user_id: number,
  last_message_id: number | undefined,
  authState: AuthState,
) => {
  const headers = makeHeaderOptionFromToken(authState);
  let list: G002Schema = initialChatDataModel();
  while (list.messages.length < RECORD_LIMIT) {
    const take_limit = Math.min(TAKE_LIMIT_SS, RECORD_LIMIT - list.messages.length);
    const ret = await chatMessagesApi.getG002(user_type, user_id, last_message_id, list.messages.length, take_limit, headers);
    if (list.messages.length == 0) {
      list = ret.data;
    } else {
      list.messages = list.messages.concat(ret.data.messages);
    }
    if (ret.data.messages.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

export const putChatMessage = (
  data: G002ChatMessageCreateSchema,
  limit: number | undefined,
  authState: AuthState,
) => {
  const headers = makeHeaderOptionFromToken(authState);
  return chatMessagesApi.putChatMessage(
    data,
    limit,
    headers
  );
};

export const fetchK002 = (
  authState: AuthState,
  fcId: number,
  begin_date: string | undefined,
  end_date: string | undefined,
  status: number | undefined,
  skip: number,
  limit: number
) => {
  const header = makeHeaderOptionFromToken(authState);
  if (begin_date == "") begin_date = undefined;
  if (end_date == "") end_date = undefined;
  return scheduleApi.getK002(fcId, begin_date, end_date, status, skip, limit, header);
};

export const fetchK002Count = (
  authState: AuthState,
  fcId: number,
  begin_date: string | undefined,
  end_date: string | undefined,
  status: number | undefined,
) => {
  const header = makeHeaderOptionFromToken(authState);
  if (begin_date == "") begin_date = undefined;
  if (end_date == "") end_date = undefined;
  return scheduleApi.getK002Count(fcId, begin_date, end_date, status, header);
};

export const fetchGeneralsForList = async (authState: AuthState, keyword: string) => {
  const header = makeHeaderOptionFromToken(authState);
  let list: GeneralForListSchema[] = [];

  while (true) {
    const take_limit = TAKE_LIMIT_M;
    const res = await generalsApi.getGeneralsForList(keyword, list.length, take_limit, header);
    list = list.concat(res.data);
    if (res.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};


export const fetchGeneralsForListMulti = async (
  authState: AuthState,
  keywords: string[]
) => {
  const header = makeHeaderOptionFromToken(authState);
  let count = 0;
  let dic: any = {};

  while (true) {
    let take_limit = TAKE_LIMIT_S;
    const res: any = await generalsApi.getGeneralsForListMulti(keywords, count, take_limit, header);

    // キーワードごとに結果を処理する
    Object.keys(res.data).forEach((keyword) => {
      if (!(keyword in dic)) {
        dic[keyword] = [];
      }
      dic[keyword] = dic[keyword].concat(res.data[keyword]);
    });

    count += take_limit;

    // 取得したデータがリクエストした数よりも少ない場合、ループを終了
    if (Object.values(res.data).flat().length < take_limit) {
      break;
    }
  }

  return { data: dic };
};

export const fetchScheduleDetailsForScheduleList = (
  authState: AuthState,
  ScheduleReservationFrameId: number
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.getK005(
    ScheduleReservationFrameId,
    header
  );
};

export const updateReservationFrame = (
  authState: AuthState,
  ScheduleReservationFrameId: number,
  ScheduleReservationFrameNum: number
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.updateReservationFrame(
    ScheduleReservationFrameId,
    ScheduleReservationFrameNum,
    header
  );
};

export const confirmReservations = (
  authState: AuthState,
  scheduleDetailIds: number[]
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.approveReservations(scheduleDetailIds, header);
};

export const cancelReservations = (
  authState: AuthState,
  scheduleDetailIds: number[]
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.cancelReservations(scheduleDetailIds, header);
};

export const fetchFacilityName = (authState: AuthState, fcId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getFcFacilityName(fcId, header);
};

export const fetchChildrenForSelectList = async (
  authState: AuthState,
  fcId: number
) => {
  const header = makeHeaderOptionFromToken(authState);
  let list: ChildSchema[] = [];
  while (true) {
    const take_limit = TAKE_LIMIT_S;
    const ret = await childrenApi.getChildrenForSelectList(fcId, list.length, take_limit, header);
    list = list.concat(ret.data);
    if (ret.data.length < take_limit) {
      break;
    }
  }
  return { data: list };
};

export const fetchChildrenForH002 = (authState: AuthState, fcId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getChildrenForH002(fcId, header);
};

export const fetchD001 = (authState: AuthState, fcId: number, full_name: string, begin_age: number = 0, end_age: number = 0, end_flg: boolean, sort_element: number = 0, sort_order: number = 0, skip: number, limit: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getChildrenForList(fcId, full_name, begin_age, end_age, end_flg, sort_element, sort_order, skip, limit, header);
};

export const fetchD001Count = (authState: AuthState, fcId: number, full_name: string, begin_age: number = 0, end_age: number = 0, end_flg: boolean) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getChildrenForListCount(fcId, full_name, begin_age, end_age, end_flg, header);
};

export const fetchH001 = (
  authState: AuthState,
  fcId: number,
  childId: number | undefined,
  beginDate: string,
  endDate: string,
  status: number,
  absenceType: number,
  skip: number,
  limit: number
) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.getTrainingH001(fcId, status, absenceType, childId, beginDate, endDate, skip, limit, header);
};

export const fetchH001Count = (authState: AuthState, fcId: number, childId: number | undefined, beginDate: string, endDate: string, status: number, absenceType: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.getTrainingH001Count(fcId, status, absenceType, childId, beginDate, endDate, header);
};

export const putP002 = (authState: AuthState, data: P002Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return programItemsApi.putP002(data, header);
};

export const putP002Movie = (authState: AuthState, id: number, file: File) => {
  const header = makeHeaderOptionFromToken(authState);
  return programItemsApi.putP002Movie(id, file, header);
};

export const fetchP002 = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return programItemsApi.getP002(id, header);
};

export const deleteP002 = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return programItemsApi.deleteP002(id, header);
};

export const deleteP002Movie = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return programItemsApi.deleteP002Movie(id, header);
};

export const fetchP001 = (authState: AuthState, step: number, reflection: number, program_name: string, skip: number, limit: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return programItemsApi.getP001(step, reflection, program_name, skip, limit, header);
};

export const fetchP001Count = (authState: AuthState, step: number, reflection: number, program_name: string) => {
  const header = makeHeaderOptionFromToken(authState);
  return programItemsApi.getP001Count(step, reflection, program_name, header);
};

export const fetchSRFList = (authState: AuthState, fcId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.getAllReservationFrames(fcId, header);
};

export const putSRF = (
  authState: AuthState,
  fcId: number,
  dataList: SRFCreateSchema[]
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.putServiceReservationFrame(fcId, dataList, header);
};

export const fetchStaffById = (authState: AuthState, fcId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcStaffsApi.getFcStaffByFcId(fcId, header);
};

export const fetchTrainingH002 = (authState: AuthState, trainingId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.getTrainingH002(trainingId, header);
};

export const fetchTrainingH003 = (authState: AuthState, trainingId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.getTrainingH003(trainingId, header);
};

export const deleteTraining = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.deleteTraining(id, header);
};

export const putH002 = (authState: AuthState, data: TrainingH002Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.putH002(data, header);
};

export const putH003 = (authState: AuthState, data: TrainingH003Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.putH003(data, header);
};

export const putNSendH003 = (authState: AuthState, data: TrainingH003Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.putSendH003(data, header);
};

export const sendH003 = (authState: AuthState, data: TrainingH003Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.sendH003(data, header);
};

export const fetchV001 = (authState: AuthState, fcId: number | undefined, beginDate: string, endDate: string, skip: number, limit: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.getV001(fcId, beginDate, endDate, skip, limit, header);
};

export const fetchV001Count = (authState: AuthState, fcId: number | undefined, beginDate: string, endDate: string) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.getV001Count(fcId, beginDate, endDate, header);
};

export const fetchV002 = (authState: AuthState, eventId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.getV002(eventId, header);
};

export const stopEventReservation = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.stopEventReservation(id, header);
};

export const fetchV003 = (authState: AuthState, eventId: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.getV003(eventId, header);
};

export const putV003 = (authState: AuthState, data: EventV003Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.putV003(data, header);
};

export const putEventImage = (authState: AuthState, id: number, file: File) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.putEventImage(id, file, header);
};

export const deleteEvent = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.deleteEvent(id, header);
};

export const putEventDetailStatus = (
  authState: AuthState,
  ids: number[],
  status: number
) => {
  const header = makeHeaderOptionFromToken(authState);
  return eventsApi.updateEventDetailStatus(status, ids, header);
};

export const fetchK001 = (
  authState: AuthState,
  fcId: number,
  beginDate: string,
  endDate: string
) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getK001(fcId, beginDate, endDate, header);
};

export const getTemporaryReservationChildCount = (
  authState: AuthState,
  fcId: number,
  beginDate: string,
  endDate: string
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.getTemporaryReservationChildCount(fcId, beginDate, endDate, header);
};

export const getChildrenUnregisterFts = (
  authState: AuthState,
  fcId: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getChildrenUnregisterFts(fcId, header);
};

export const getChildrenUnregisterDisabilityCertificate = (
  authState: AuthState,
  fcId: number,
  year: number,
  month: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getChildrenUnregisterDisabilityCertificate(fcId, year, month, header);
};

export const fetchK001Weekly = (
  authState: AuthState,
  fcId: number,
  beginDate: string,
  endDate: string
) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getK001Weekly(fcId, beginDate, endDate, header);
};

export const addSlotK001Weekly = (
  authState: AuthState,
  fcId: number,
  date: string,
  startTime: string,
  endTime: string,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.putReservationFrameForK001Weekly(fcId, date, startTime, endTime, header);
};

export const reduceSlotK001Weekly = (
  authState: AuthState,
  schedule_reservation_id: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.reduceReservationFrameForK001Weekly(schedule_reservation_id, header);
};

export const putK001WeeklyPic = (
  authState: AuthState,
  trainingId: number,
  staffId: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return trainingsApi.putK001WeeklyPic(trainingId, staffId, header);
};

export const putReservationFrameOnFixedTimeSlot = (
  authState: AuthState,
  fcId: number,
  year: number,
  month: number
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.putReservationFrameOnFixedTimeSlot(fcId, year, month, header);
};

export const getLastReservationRequestMonth = (
  authState: AuthState,
  fcId: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getLastReservationRequestMonth(fcId, header);
};

export const updateSRFNotified = (
  authState: AuthState,
  fcId: number,
  startDate: string,
  endDate: string,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return scheduleApi.updateSRFNotified(fcId, startDate, endDate, header);
};

export const fetchHolidaySettingTheMonth = (
  authState: AuthState,
  fcId: number,
  year: number,
  month: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return holidayPlannerApi.getHolidayPlanner(fcId, year, month, header);
};

export const putK003 = (
  authState: AuthState,
  fcId: number,
  year: number,
  month: number,
  dateList: K003PutRequestSchema,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return holidayPlannerApi.putHolidayPlanner(fcId, year, month, dateList, header);
};

export const fetchM001 = (
  authState: AuthState,
  fcId: number,
  childId: number,
  beginDate: string,
  endDate: string,
  docType: number,
  status: number,
  date_type: number,
  skip: number,
  limit: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return auditDocsApi.getM001(date_type, fcId, childId, beginDate, endDate, docType, status, skip, limit, header);
};

export const fetchM001Count = (
  authState: AuthState,
  fcId: number,
  childId: number,
  beginDate: string,
  endDate: string,
  docType: number,
  status: number,
  date_type: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return auditDocsApi.getM001Count(date_type, fcId, childId, beginDate, endDate, docType, status, header);
};

export const fetchM001DocksCount = (
  authState: AuthState,
  fcId: number,
  childId: number,
  beginDate: string,
  endDate: string,
  docType: number,
  date_type: number,
) => {
  const header = makeHeaderOptionFromToken(authState);
  return auditDocsApi.getM001DocsCount(date_type, fcId, childId, beginDate, endDate, docType, header);
};

export const downloadM001 = (
  authState: AuthState,
  fcId: number,
  childId: number,
  beginDate: string,
  endDate: string,
  docType: number,
  date_type: number,
): Promise<AxiosResponse<Blob>> => {
  const oauth = authState.token;
  const accessToken = oauth ? oauth.access_token : "";
  const header = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    timeout: 60000,
    responseType: "blob" as "blob",
  };
  return auditDocsApi.downloadM001Docs(date_type, fcId, childId, beginDate, endDate, docType, header)
  .then(response => {
    return response as unknown as AxiosResponse<Blob>;
  });};

export const fetchM002 = (authState: AuthState, audit_doc_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return auditDocsApi.getM002(audit_doc_id, header);
};

export const putM002 = (authState: AuthState, data: AuditDocM002Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return auditDocsApi.putM002(data, header);
};

export const putAuditDocImage = (
  authState: AuthState,
  id: number,
  file: File
) => {
  const header = makeHeaderOptionFromToken(authState);
  return auditDocsApi.putAuditDocFile(id, file, header);
};

export const deleteAuditDoc = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return auditDocsApi.deleteAuditDoc(id, header);
};

export const fetchAddress = async (postCode: string | undefined) => {
  try {
    if (!postCode) return null;
    const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCode}`);
    const data = await response.json();
    const result = data.results[0];
    return `${result.address1}${result.address2}${result.address3}`;
  } catch (error) {
    return null;
  }
};

export const fetchD007 = (authState: AuthState, child_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getD007(child_id, header);
};

export const fetchD008 = (authState: AuthState, child_id: number, disability_certificate_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getD008(child_id, disability_certificate_id, header);
};

export const putD008 = (authState: AuthState, data: ChildD008Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.putD008(data, header);
};

export const fetchD010 = (authState: AuthState, child_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.getD010(child_id, header);
};

export const fetchChildInfo = (childId: number, authState: AuthState) => {
  const headers = makeHeaderOptionFromToken(authState);
  return childrenApi.getChildInfo(childId, headers);
};

export const putD010 = (authState: AuthState, child_id: number, data: D010Schema[]) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.putD010(child_id, data, header);
};

export const deleteDisabilityCertificate = (authState: AuthState, id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return childrenApi.deleteDisabilityCertificate(id, header);
};

export const fetchJ001Send = (authState: AuthState, from: number, to_type: number, to: number, begin_date: string, end_date: string, skip: number, limit: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.getJ001Send(from, to_type, to, begin_date, end_date, skip, limit, header);
};

export const fetchJ001SendCount = (authState: AuthState, from: number, to_type: number, to: number, begin_date: string, end_date: string) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.getJ001SendCount(from, to_type, to, begin_date, end_date, header);
};

export const fetchJ001Receive = async (authState: AuthState, begin_date: string, end_date: string, skip: number, limit: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.getJ001Receive(begin_date, end_date, skip, limit, header);
};

export const fetchJ001ReceiveCount = async (authState: AuthState, begin_date: string, end_date: string) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.getJ001ReceiveCount(begin_date, end_date, header);
};

export const fetchJ002 = (authState: AuthState, notification_make_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.getJ002(notification_make_id, header)
};

export const putJ002 = (authState: AuthState, data: J002Schema) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.putJ002(data, header);
};

export const putJ002Image = (authState: AuthState, notification_make_id: number, file: File) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.putJ002Image(notification_make_id, file, header);
};

export const deleteJ002 = (authState: AuthState, notification_make_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.deleteJ002(notification_make_id, header);
};

export const sendJ002 = (authState: AuthState, notification_make_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.sendJ002(notification_make_id, header);
};

export const fetchJ003 = (authState: AuthState, notification_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return notificationsApi.getJ003(notification_id, header)
};

export const fetchB001 = (authState: AuthState, facility_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getB001(facility_id, header);
};

export const fetchB001Headquarter = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return fcFacilitiesApi.getB001Headquarter(header)
};

export const fetchW001 = (authState: AuthState, facility_id: number, skip: number, limit: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return signatureRequestsApi.getW001(facility_id, skip, limit, header);
};

export const fetchW001Count = (authState: AuthState, facility_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return signatureRequestsApi.getW001Count(facility_id, header);
};

export const fetchW002 = (authState: AuthState, signature_request_make_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return signatureRequestsApi.getW002(signature_request_make_id, header)
};

export const putW002 = (authState: AuthState, facility_id: number, doc_type: number, publication_date: string, file: File) => {
  const header = makeHeaderOptionFromToken(authState);
  return signatureRequestsApi.putW002(facility_id, doc_type, publication_date, file, header)
};

export const notifyW002 = (authState: AuthState, signature_request_make_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return signatureRequestsApi.notifyW002(signature_request_make_id, header)
};

export const deleteW002 = (authState: AuthState, signature_request_make_id: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return signatureRequestsApi.deleteW002(signature_request_make_id, header)
};

export const fetchTemplateUrl = (authState: AuthState, file_type: number) => {
  const header = makeHeaderOptionFromToken(authState);
  return templatesApi.getTemplate(file_type, header);
};

export const testScheduleReservation = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return testsApi.scheduleReservation(header);
};

export const testSignTrainingResult = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return testsApi.signTrainingResultForAll(header);
};

export const testSignChangeConsentFormForImportantInformationManual = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return testsApi.signChangeConsentFormForImportantInformationManual(header);
};

export const testSignIndividualSupportPlanAll = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return testsApi.signIndividualSupportPlanAll(header);
};

export const testPutEachEventOne = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return testsApi.putEachEventOne(header);
};

export const batchMakeIndividualSupportPlan = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return batchApi.makeIndividualSupportPlan(header);
};

export const batchMakeAssessmentSheet = (authState: AuthState) => {
  const header = makeHeaderOptionFromToken(authState);
  return batchApi.makeAssessmentSheet(header);
};

export const contactSendMessage = (email: string, name: string, message: string, header: any) => {
  return contactApi.sendMessage(email, name, message, header);
};