import "./K003.scss";
import { WNB_CLASS } from "../../components/Button/WeeklyNameButton";

const DescribeCalendar = () => {

  return (
    <div className="mx-auto w-75">
      <div className="on_same_row my-2">
        <div className={"weekly_name_button describe_icon reserved " + WNB_CLASS.NONE}></div>
        <div className="describe_text ms-3">平日</div>
      </div>
      <div className="on_same_row my-2">
        <div className={"weekly_name_button describe_icon " + WNB_CLASS.LIGHT_PINK}></div>
        <div className="describe_text ms-3">土日祝営業日</div>
      </div>
      <div className="on_same_row my-2">
        <div className={"weekly_name_button describe_icon " + WNB_CLASS.GRAY}></div>
        <div className="describe_text ms-3">施設休業日</div>
      </div>
    </div>
  );
};

export default DescribeCalendar;