import React from "react";
import { Form } from "react-bootstrap";
import "./TextBox.scss";

function TextBox(props: any) {
  return (
    <Form.Control
      type={props.type}
      className={"custom_textBox mx-2 " + props.class}
      placeholder={props.placeholder}
      value={props.value == null ? "" : props.value}
      onChange={(e) => props.onChange(e.target.value)}
      style={{ width: props.width }}
      disabled={props.disabled}
      maxLength={props.maxLength == null ? 255 : props.maxLength}
    />
  );
}

export default TextBox;
