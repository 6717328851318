import moment from "moment";
import { K001WeeklySchema } from "../generated";

export const DEFAULT_HOLIDAY = "0000000";
export const FLG_HOLIDAY = "1";
export const FLG_WEEKDAY = "2";
export const FLG_CLOSEDAY = "3";
export const FLG_NOT_TARGET_MONTH = 1;
export const FLG_REGULAR_HOLIDAY = 2;
export const FLG_WORKING_DAY_AT_TARGET_MONTH = 3;

const WEEKDAY_FRAME_HOUR = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
const HOLIDAY_FRAME_HOUR = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
export const DISPLAY_FRAME_INDEX = [1, 2, 3, 4, 5, 6, 7, 8];

export interface MonthYear {
  month: number;
  year: number;
}

export const initialMonthYear = (): MonthYear => ({
  month: moment(new Date()).month(),
  year: moment(new Date()).year(),
});

export interface iCalendarColor {
  yellow: boolean;
  red: boolean;
  blue: boolean;
  green: boolean;
}

export const initialCalendarColor = (): iCalendarColor => ({
  yellow: false,
  red: true,
  blue: true,
  green: false,
});

export const initialK001WeeklyModel = (): K001WeeklySchema => ({
  weekday_training_start_min: 45,
  holiday_training_start_min: 0,
  weekly_schedule_weekday: [],
  weekly_schedule_holiday: [],
  holiday_plan: [],
});

export const makeFrameList = (minute: number, flgWeekdayOrHoliday: string) => {
  const baseFrame = flgWeekdayOrHoliday == FLG_WEEKDAY ? WEEKDAY_FRAME_HOUR : HOLIDAY_FRAME_HOUR;
  return baseFrame.map((hour) => {
    return `${hour}:${("00" + minute).slice(-2)}`;
  })
};

export const isWithinRangeTheTime = (startRange: string, endRange: string, targetTime: string) => {
  let isWithinRange = false;

  const start_hour = startRange.slice(0, startRange.indexOf(":"));
  const start_minute = startRange.slice(startRange.indexOf(":") + 1);
  const start = new Date("2000-1-1 00:00:00");
  start.setHours(Number(start_hour));
  start.setMinutes(Number(start_minute));

  const end_hour = endRange.slice(0, endRange.indexOf(":"));
  const end_minute = endRange.slice(endRange.indexOf(":") + 1);
  const end = new Date("2000-1-1 00:00:00");
  end.setHours(Number(end_hour));
  end.setMinutes(Number(end_minute));

  const target_hour = targetTime.slice(0, targetTime.indexOf(":"));
  const target_minute = targetTime.slice(targetTime.indexOf(":") + 1);
  const target = new Date("2000-1-1 00:00:00");
  target.setHours(Number(target_hour));
  target.setMinutes(Number(target_minute));

  isWithinRange = start <= target && target < end;

  return isWithinRange
};