import React, { useEffect, useState } from "react";
import { addMonths, format, getDate, getDay, subMonths } from "date-fns";
import "./Calendar.scss";
import { FLG_HOLIDAY, initialCalendarColor } from "../../models/schedule.model";
import { getCalendarArray } from "../../helper/schedule.helper";
import { H001Url, K002Url, V001Url, getForHistoryPush } from "../../helper/url.helper";
import RoundButton, { RB_COLOR } from "../../components/Button/RoundButton";
import { SCHEDULE_DETAIL_STATUS } from "../../models/scheduleReservationFrame.model";
import { FCFacilityK001Schema } from "../../generated";
import { useHistory } from "react-router";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { fetchK001, getTemporaryReservationChildCount } from "../../services/api.service";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { notifyError } from "../../helper/settings.helper";
import SwitchButton, { SB_COLOR } from "../../components/Button/SwitchButton";
import { useAuth } from "../../helper/auth.helper";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import Label from "../../components/Label/Label";

interface CalendarProps {
  targetDate: Date;
  setTargetDate: React.Dispatch<React.SetStateAction<Date>>;
  fcId: number;
  holiday: string;
}

const Calendar: React.VFC<CalendarProps> = ({
  targetDate,
  setTargetDate,
  fcId,
  holiday,
}) => {
  const authState = useSelector(authSelector);
  const calendar = getCalendarArray(targetDate);
  const history = useHistory();
  const holidayArray = (holiday.slice(-1) + holiday.slice(0, -1)).split("");
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [reservationDays, setReservationDays] = useState<FCFacilityK001Schema[]>([]);
  const { checkLoggedIn } = useAuth();
  const [getData, setGetData] = useState(true);
  const [calendarColor, setCalendarColor] = useState(initialCalendarColor());
  const [temporaryChildren, setTemporaryChildren] = useState<number>(0);

  useEffect(() => {
    if (fcId) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        const currentDate = targetDate;
        const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const formattedFirstDayOfLastMonth = format(firstDayOfLastMonth, "yyyy-MM-dd");
        const formattedLastDayOfThisMonth = format(lastDayOfThisMonth, "yyyy-MM-dd");
        try {
          const [resFetchK001, resGetTemporaryChildren] = await Promise.all([
            fetchK001(authState, fcId, formattedFirstDayOfLastMonth, formattedLastDayOfThisMonth),
            getTemporaryReservationChildCount(authState, fcId, formattedFirstDayOfLastMonth, formattedLastDayOfThisMonth),
          ]);
          if (isMounted) {
            setReservationDays(resFetchK001.data);
            setTemporaryChildren(resGetTemporaryChildren.data);
            setGetData(false);
          }
          changeIsLoading();
        } catch (err) {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        }
      };
      if (getData) {
        fetchData();
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, targetDate, getData]);

  useEffect(() => {
    setGetData(true);
  }, [fcId]);

  const changeCurrentMonth = (pushMoveToNextMonth: boolean) => {
    pushMoveToNextMonth ? setTargetDate((current) => addMonths(current, 1)) : setTargetDate((current) => subMonths(current, 1));
    setGetData(true);
  };

  return (
    <div className="calendar_container">
      <div className="on_same_row justify">
        <div className="on_same_row">
          <MdNavigateBefore size="40px" className="move_calendar" onClick={() => {
            changeCurrentMonth(false);
          }}/>
          <MdNavigateNext size="40px" className="move_calendar" onClick={() => {
            changeCurrentMonth(true);}
          }/>
          <h1 className="title-year-month m-0">{format(targetDate, "y年M月")}</h1>
        </div>
        <div className="on_same_row">
          {reservationDays.filter(x => x.remaining > 0).length > 0 &&
            <SwitchButton label="予約空枠" color={SB_COLOR.YELLOW} is_none={!calendarColor.yellow} onClick={() => setCalendarColor({ ...calendarColor, yellow: !calendarColor.yellow })} />
          }
          {reservationDays.filter(x => x.reserved > 0).length > 0 &&
            <SwitchButton label="承認待ち" color={SB_COLOR.RED} is_none={!calendarColor.red} onClick={() => setCalendarColor({ ...calendarColor, red: !calendarColor.red })} />
          }
          {reservationDays.filter(x => x.training > 0).length > 0 &&
            <SwitchButton label="トレーニング" color={SB_COLOR.BLUE} is_none={!calendarColor.blue} onClick={() => setCalendarColor({ ...calendarColor, blue: !calendarColor.blue })} />
          }
          {reservationDays.filter(x => x.event != undefined).length > 0 &&
            <SwitchButton label="イベント" color={SB_COLOR.GREEN} is_none={!calendarColor.green} onClick={() => setCalendarColor({ ...calendarColor, green: !calendarColor.green })} />
          }
        </div>
      </div>
      <Label value={(targetDate.getMonth() + 1) + "月のトレーニング予約未確定数：" + temporaryChildren + "人"}/>
      <div className="cl_table_aea">
        <table className="cl_table">
          <thead>
            <tr className="cl_head">
              <th className="cl_head_title">日</th>
              <th className="cl_head_title">月</th>
              <th className="cl_head_title">火</th>
              <th className="cl_head_title">水</th>
              <th className="cl_head_title">木</th>
              <th className="cl_head_title">金</th>
              <th className="cl_head_title">土</th>
            </tr>
          </thead>
          <tbody>
            {calendar.map((weekRow, rowNum) => (
              <tr key={rowNum}>
                {weekRow.map((date) => {
                  const dateStr = format(date, "yyyy-MM-dd");
                  const calendarDay = reservationDays.filter(x => x.date == dateStr).length > 0
                    ? reservationDays.filter(x => x.date == dateStr)[0] : undefined;
                  return (
                    <td className={(holidayArray[date.getDay()] == FLG_HOLIDAY) || (calendarDay && calendarDay.closed > 0) ? "cl_day_holiday" : "cl_day_default"} key={getDay(date)}>
                      {getDate(date)}
                      {calendarDay &&
                        <div className="on_same_row justify_center">
                          {(calendarDay.remaining > 0) &&
                            <RoundButton label={calendarDay.remaining} color={RB_COLOR.YELLOW} is_none={!calendarColor.yellow}
                              onClick={() => history.push(getForHistoryPush(K002Url, [fcId, dateStr, dateStr, 0]))} />
                          }
                          {(calendarDay.reserved > 0) &&
                            <RoundButton label={calendarDay.reserved} color={RB_COLOR.RED} is_none={!calendarColor.red}
                              onClick={() => history.push(getForHistoryPush(K002Url, [fcId, dateStr, dateStr, SCHEDULE_DETAIL_STATUS.RESERVED]))} />
                          }
                          {(calendarDay.training > 0) &&
                            <RoundButton label={calendarDay.training} color={RB_COLOR.BLUE} is_none={!calendarColor.blue}
                              onClick={() => history.push(getForHistoryPush(H001Url, [0, fcId, dateStr, dateStr]))} />
                          }
                          {(calendarDay.event != undefined) &&
                            <RoundButton label={calendarDay.event} color={RB_COLOR.GREEN} is_none={!calendarColor.green}
                              onClick={() => history.push(getForHistoryPush(V001Url, [fcId, dateStr, dateStr]))} />
                          }
                        </div>
                      }
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
