import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { Alert, FormCheck } from "react-bootstrap";
import {
  GeneralForListSchema,
  K005ScheduleDetailSchema,
  K005Schema
} from "../../generated";
import { C002Url, D002Url, G001Url, H002Url, H003Url, getForHistoryPush } from "../../helper/url.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import {
  fetchScheduleDetailsForScheduleList,
  fetchGeneralsForList,
  updateReservationFrame,
  confirmReservations,
  cancelReservations
} from "../../services/api.service";
import { getReservationNum, getApprovedNum } from "../../helper/schedule.helper"
import IncDec from "../../components/IncDec/IncDec"
import { PARENT, TRAIN_MAX_NUM_IN_A_FRAME } from "../../constants.js"
import Whistle from "../../components/Icon/Whistle"
import Chat from "../../components/Icon/Chat"
import User from "../../components/Pict/User"
import ConfirmModal from "../../components/ConfirmModal"
import Status, { STATUS_RED, STATUS_BLUE, STATUS_GRAY, StatusSchema, STATUS_GREEN } from "../../components/Status/Status"
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { LIST_RESERVATION_STATUS } from "../../constants";
import {
  initialScheduleReservationFrameForScheduleListSchema,
  SCHEDULE_DETAIL_STATUS,
} from "../../models/scheduleReservationFrame.model";
import { notify, notifyError } from "../../helper/settings.helper";
import PaginationTable from "../../components/PaginationTable/PaginationTable"
import FacilityName from "../../components/FacilityName/FacilityName";
import { TRAINING_STATUS_NOT_PLANNED, TRAINING_STATUS_NOT_TRAINED } from "../../models/training.model";
import { useAuth } from "../../helper/auth.helper";
import { validationMessage } from "../../config/validationMessage";
import { makeStringDateTime } from "../../helper/time.helper";

interface iLocation {
  facility_id: number;
  schedule_reservation_frame_id: number;
}

const K005: React.VFC = () => {
  const { checkLoggedIn } = useAuth();
  const userInfo = useSelector(userInfoSelector);
  const maxWidth = 900;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const location = useLocation<iLocation>();
  const fcId = location.state.facility_id;
  const reservationFrameId = location.state.schedule_reservation_frame_id;
  const [reservationStatusList, setReservationStatusList] = useState<GeneralForListSchema[]>([]);
  const [checkedReserved, setCheckedReserved] = useState(false);
  const [checkedApproved, setCheckedApproved] = useState(false);
  const [checkedReservedMoreThanRemaining, setCheckedReservedMoreThanRemaining] = useState(false);
  const status_list: StatusSchema[] = [
    { num: SCHEDULE_DETAIL_STATUS.RESERVED, class: STATUS_RED },
    { num: SCHEDULE_DETAIL_STATUS.APPROVED, class: STATUS_BLUE },
    { num: SCHEDULE_DETAIL_STATUS.CANCELED, class: STATUS_GRAY },
    { num: SCHEDULE_DETAIL_STATUS.TEMPORARY, class: STATUS_GREEN },
  ];
  const [showApproval, setShowApproval] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [reload, setReload] = useState(true);
  const [data, setData] = useState<K005Schema>(initialScheduleReservationFrameForScheduleListSchema());
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [num, setNum] = useState(0);

  const clickCheck = (index: number) => {
    if (checkedList.indexOf(index) == -1) {
      setCheckedList([...checkedList, index]);
    } else {
      const list = checkedList.filter(x => x != index);
      setCheckedList(list);
    }
  };

  const clickUpdateReservation = () => {
    changeIsLoading();
    updateReservationFrame(authState, data.id, num).then((_) => {
      notify("予約数を変更しました。");
      setReload(true);
      changeIsLoading();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const clickApproval = () => {
    changeIsLoading();
    let reservationIds: number[] = [];
    checkedList.map(x => {
      reservationIds.push(data.schedule_details[x].id);
    });
    confirmReservations(authState, reservationIds).then((res) => {
      if (res.data.result) {
        notify("承認しました。");
      } else {
        notifyError(res.data.msg || "承認に失敗しました。");
      }
      setCheckedList([]);
      setShowApproval(false);
      changeIsLoading();
      setReload(true);
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const clickCancel = () => {
    changeIsLoading();
    let reservationIds: number[] = [];
    checkedList.map(x => {
      reservationIds.push(data.schedule_details[x].id);
    });
    cancelReservations(authState, reservationIds).then((_) => {
      notify("キャンセルしました。");
      setShowCancel(false);
      setCheckedList([]);
      setReload(true);
      changeIsLoading();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    let cktReserved = false;
    let cktApproved = false;
    let cntReserved = 0;
    checkedList.map(x => {
      if (data.schedule_details[x].status == SCHEDULE_DETAIL_STATUS.RESERVED || data.schedule_details[x].status == SCHEDULE_DETAIL_STATUS.TEMPORARY) {
        cktReserved = true;
        cntReserved++;
      }
      if (data.schedule_details[x].status == SCHEDULE_DETAIL_STATUS.APPROVED) {
        cktApproved = true;
      }
    });
    setCheckedReservedMoreThanRemaining(cntReserved > (data.num - getApprovedNum(data.schedule_details)));
    setCheckedReserved(cktReserved);
    setCheckedApproved(cktApproved);
  }, [checkedList, data]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_RESERVATION_STATUS).then((res) => {
        if (isMounted) {
          setReservationStatusList(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (reservationStatusList.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, reservationStatusList.length]);

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchScheduleDetailsForScheduleList(authState, reservationFrameId).then((res) => {
          if (isMounted) {
            setData(res.data);
            setNum(res.data.num);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, reservationFrameId, reload]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="スケジュール詳細" />
      <FacilityName fcId={fcId} authState={authState} />
      <div className="plate_no_margin on_same_row">
        <h4>{data.date}</h4>
        <h4 className="ps-3">{data.start_time} - {data.end_time}</h4>
      </div>
      <div className="plate_no_margin on_same_row">
        <p>枠数：{num}</p>
        <p>予約数：{getReservationNum(data.schedule_details)}</p>
        <p>残数：{(num ?? 0) - getApprovedNum(data.schedule_details)}</p>
      </div>
      <div></div>
      <div className="plate_no_margin margin_1">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            <div className="item">
              <IncDec
                val={num}
                inc={() => setNum(num + 1)}
                dec={() => setNum(num - 1)}
                min_val={getApprovedNum(data.schedule_details)}
                max_val={TRAIN_MAX_NUM_IN_A_FRAME}
              />
            </div>
            <CustomButton label="枠数変更" class={[CB_CLASS.RED]} onClick={clickUpdateReservation} />
          </div>
          <div className="on_same_row">
            {(checkedReserved && !checkedApproved && !checkedReservedMoreThanRemaining) &&
              <>
                <CustomButton label="承認" class={[CB_CLASS.RED]} onClick={() => setShowApproval(true)} />
                <ConfirmModal target="承認" show={showApproval} setShow={setShowApproval} func={clickApproval}
                  confirmText={"承認してよろしいでしょうか。"} />
              </>
            }
            {(checkedReserved || checkedApproved) &&
              <>
                <CustomButton label="キャンセル" class={[CB_CLASS.RED]} onClick={() => setShowCancel(true)} />
                <ConfirmModal target="キャンセル" show={showCancel} setShow={setShowCancel} func={clickCancel}
                  confirmText={"キャンセルしてよろしいでしょうか。"}
                />
              </>
            }
          </div>
        </div>
      </div>
      <PaginationTable
        limit={10}
        max_width={maxWidth}
        tr_select={false}
        headers={["", "", "お子様", "", "保護者", "", "予約日時", "", "予約状況"]}
        list={data.schedule_details && data.schedule_details.map((x: K005ScheduleDetailSchema, i) => (
          <tr key={i} className="hover_white">
            <td className="item" valign="middle" align="center">
              {(x.status !== SCHEDULE_DETAIL_STATUS.CANCELED &&
                // x.status !== SCHEDULE_DETAIL_STATUS.TEMPORARY &&
                ([TRAINING_STATUS_NOT_TRAINED, TRAINING_STATUS_NOT_PLANNED].indexOf(x.training_status || 0) > -1 || x.training_id === null)) &&
                <FormCheck checked={checkedList.indexOf(i) > -1} onChange={() => clickCheck(i)} />
              }
            </td>
            <td></td>
            <td className="item" valign="middle" align="center">
              <div className="on_same_row justify_center">
                <User url={x.child_profile_pic_url} func={() => history.push(getForHistoryPush(D002Url, [x.child_id]))} />
                <div className="item">
                  {x.child_name}
                </div>
                {x.training_id &&
                  <Whistle func={() => {
                    if ([TRAINING_STATUS_NOT_TRAINED, TRAINING_STATUS_NOT_PLANNED].indexOf(x.training_status || 0) > -1) {
                      history.push(getForHistoryPush(H002Url, [x.training_id, 0, "K005"]));
                    } else {
                      history.push(getForHistoryPush(H003Url, [x.training_id, "K005"]));
                    }
                  }} />
                }
              </div>
              {(!x.has_disability_certificate || x.is_empty_disability_certificate_number) &&
                <Alert
                  variant="danger"
                  style={{ padding: 5, fontSize: 14, margin: 0 }}
                >
                  <p className="text-wrap">{
                    !x.has_disability_certificate
                      ? validationMessage.noDisabilityCertificate
                      : validationMessage.emptyDisabilityCertificate
                  }</p>
                </Alert>
              }
            </td>
            <td></td>
            <td className="item" valign="middle" align="center">
              <div className="on_same_row justify_center">
                <User
                  url={x.parent_profile_pic_url}
                  func={() => history.push(getForHistoryPush(C002Url, [x.parent_id]))}
                />
                <div className="item">
                  {x.parent_name}
                </div>
                <Chat user_type={userInfo?.user_type} func={() => history.push(getForHistoryPush(G001Url, [x.parent_id, PARENT]))} />
              </div>
            </td>
            <td></td>
            <td className="item" valign="middle" align="center">
              {makeStringDateTime(x.created_at)}
            </td>
            <td></td>
            <td className="item" valign="middle" align="center">
              <Status label_list={reservationStatusList} color_list={status_list} status={x.status} />
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default K005;
