import "./H003.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  GeneralForListSchema,
  TrainingH003Schema,
  ProgramItemForSelectSchema,
  ProgramSelectSchema,
  ReflectionSchema,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  fetchGeneralsForListMulti,
  fetchTrainingH003,
  fetchProgramForSelect,
  fetchStaffById,
  putH003,
  sendH003,
  deleteTraining,
  putNSendH003,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_ALIGN, CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import {
  initialTrainingH003Model,
  initialProgramSelectModel,
  validationTrainingH003Model,
  cautionTrainingH003Model,
  TRAINING_STATUS_SENT,
  SERVICE_PROVISION_STATUS,
  TRAINING_STATUS_SIGNED,
} from "../../models/training.model";
import { initialGeneralForList } from "../../models/general.model";
import { LIST_CHILDCARE_SUPPORT_ADDITION, LIST_CONSULTATION_SUPPORT_ADDITION, LIST_PROVIDED_FORM, LIST_REFLECTION, LIST_SERVICE_PROVISION_STATUS, LIST_SPECIALIST_SUPPORT_ADDITION, LIST_STEP } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import { H002Url, M002Url, getForHistoryPush } from "../../helper/url.helper";
import { Form, FormLabel } from "react-bootstrap";
import Delete from "../../components/Icon/Delete";
import Play from "../../components/Icon/Play";
import CheckBox from "../../components/CheckBox/CheckBox"
import NumBox, { NUM_BOX_TYPE } from "../../components/TextBox/NumBox";
import Label from "../../components/Label/Label";
import DraggableList from "../../components/DraggableList/DraggableList";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import { CautionError, initialCautionError } from "../../models/caute.model";
import { notify, notifyError } from "../../helper/settings.helper";
import ConfirmModal from "../../components/ConfirmModal";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import ErrorMsg from "../../components/ErrorMsg/ErrorMsg";
import { getState } from "../../helper/page.helper";
import { getFormatDate } from "../../helper/date.helper";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import CustomTime from "../../components/Calendar/CustomTime";
import { DISABILITY_CERTIFICATE_SUPPORT_TYPE } from "../../models/child.model";
import { useAuth } from "../../helper/auth.helper";
import { pgListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import CautionMsg from "../../components/CautionMsg/CautionMsg";
import TextBox from "../../components/TextBox/TextBox";

interface iLocation {
  training_id: number;
  from: string;
}

interface iSelects {
  reflection: GeneralForListSchema[],
  program: GeneralForListSchema[],
}

interface iSelected {
  step: number,
  reflection: number,
}

const initialSelected = (): iSelected => ({
  step: 0,
  reflection: 0
});

const H003 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iLocation>();
  const state = getState(location);
  const trainingId = state.training_id;
  const from = state.from;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [staffForSelect, setStaffForSelect] = useState<GeneralForListSchema[]>([]);
  const [stepForSelect, setStepForSelect] = useState<GeneralForListSchema[]>([]);
  const [reflectionList, setReflectionList] = useState<GeneralForListSchema[]>([]);
  const [serviceProvisionStatusForSelect, setServiceProvisionStatusForSelect] = useState<GeneralForListSchema[]>([]);
  const [consultationSupportAdditionForSelect, setConsultationSupportAdditionForSelect] = useState<GeneralForListSchema[]>([]);
  const [specialistSupportAdditionForSelect, setSpecialistSupportAdditionForSelect] = useState<GeneralForListSchema[]>([]);
  const [childcareSupportAdditionForSelect, setChildcareSupportAdditionForSelect] = useState<GeneralForListSchema[]>([]);
  const [providedFormForSelect, setProvidedFormForSelect] = useState<GeneralForListSchema[]>([]);
  const [programList, setProgramList] = useState<ProgramItemForSelectSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [selectedList, setSelectedList] = useState<iSelected[]>([initialSelected()]);
  const [data, setData] = useState<TrainingH003Schema>(initialTrainingH003Model());
  const [originalData, setOriginalData] = useState<TrainingH003Schema>(initialTrainingH003Model());
  const [selectList, setSelectList] = useState<iSelects[]>([]);
  const [showRegister, setShowRegister] = useState(false);
  const [showSendRegister, setShowSendRegister] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [showResendRegister, setShowResendRegister] = useState(false);
  const [showReferencing, setShowReferencing] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [validationError, setValidationError] = useState<ValidationError<TrainingH003Schema>>(initialValidationError());
  const [cautionError, setCautionError] = useState<CautionError<TrainingH003Schema>>(initialCautionError());
  const [reload, setReload] = useState(true);
  const noInputList = [
    SERVICE_PROVISION_STATUS.ABSENCE,
    SERVICE_PROVISION_STATUS.ABSENCE_1,
    SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY,
  ];
  const trainingStatusList = [
    TRAINING_STATUS_SENT,
    TRAINING_STATUS_SIGNED
  ];
  const trainingStatus = trainingStatusList.indexOf(data.status || 0) > -1;
  const noInput = noInputList.indexOf(data.service_provision_status || 0) > -1;
  const pgListState = useSelector(pgListSelector);
  const dispatch = useDispatch();
  const [timeTotal, setTimeTotal] = useState(0);
  const [modeResend, setModeResend] = useState(false);
  const [remarksLength, setRemarksLength] = useState(0);
  const [messageLength, setMessageLength] = useState(0);

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        try {
          const [resH003, resGen] = await Promise.all([
            fetchTrainingH003(authState, trainingId),
            fetchGeneralsForListMulti(authState, [
              LIST_CONSULTATION_SUPPORT_ADDITION,
              LIST_SERVICE_PROVISION_STATUS,
              LIST_SPECIALIST_SUPPORT_ADDITION,
              LIST_CHILDCARE_SUPPORT_ADDITION
            ]),
          ]);

          if (isMounted) {
            setSelectedList(resH003.data.program_adds.map((x: any) => ({ step: x.program_item.step, reflection: x.program_item.reflections[0].reflection })));

            setData(resH003.data);
            setOriginalData(JSON.parse(JSON.stringify(resH003.data)));

            if (data.remarks) {
              setRemarksLength(data.remarks.length);
            }
            if (data.message) {
              setMessageLength(data.message.length);
            }
            const resGenData = resGen.data as any;
            if (resH003.data.service_provision_status != SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY) {
              // 「家族支援加算のみ」以外の場合、空を選択肢に含める
              resGenData[LIST_CONSULTATION_SUPPORT_ADDITION].unshift(initialGeneralForList());
            }
            setConsultationSupportAdditionForSelect(resGenData[LIST_CONSULTATION_SUPPORT_ADDITION]);
            const attendanceList = [SERVICE_PROVISION_STATUS.ABSENCE_2, SERVICE_PROVISION_STATUS.ATTENDANCE];
            // 「出席」、「欠席Ⅱ」の場合、「出席、欠席Ⅱ」のどちらかしか選択できないようにする
            if (attendanceList.indexOf(resH003.data.service_provision_status || 0) > -1) {
              setServiceProvisionStatusForSelect(
                resGenData[LIST_SERVICE_PROVISION_STATUS].filter((x: GeneralForListSchema) => attendanceList.indexOf(x.value) > -1)
              );
            } else {
              setServiceProvisionStatusForSelect(resGenData[LIST_SERVICE_PROVISION_STATUS]);
            }
            [LIST_SPECIALIST_SUPPORT_ADDITION, LIST_CHILDCARE_SUPPORT_ADDITION].map(x => {
              resGenData[x].unshift(initialGeneralForList());
            });
            setSpecialistSupportAdditionForSelect(resGenData[LIST_SPECIALIST_SUPPORT_ADDITION]);
            setChildcareSupportAdditionForSelect(resGenData[LIST_CHILDCARE_SUPPORT_ADDITION]);
            fetchStaffById(authState, resH003.data.facility_id).then((_res) => {
              if (isMounted) {
                setStaffForSelect([
                  initialGeneralForList(),
                  ..._res.data.map((x) => ({
                    value: x.id,
                    label: x.family_name + x.given_name,
                  })),
                ]);
                setReload(false);
              }
              changeIsLoading();
            }).catch((err) => {
              notifyError("データの取得に失敗しました。");
              console.error(err);
              changeIsLoading();
            });
          }
        } catch (err) {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        }
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, trainingId, reload, data.remarks, data.message]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForListMulti(
        authState,
        [LIST_STEP, LIST_REFLECTION, LIST_PROVIDED_FORM]
      ).then((res) => {
        if (isMounted) {
          const resGenData = res.data as any;
          resGenData[LIST_STEP].unshift(initialGeneralForList());
          setStepForSelect(resGenData[LIST_STEP]);
          setReflectionList(resGenData[LIST_REFLECTION]);
          setProvidedFormForSelect(resGenData[LIST_PROVIDED_FORM]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (stepForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [authState, changeIsLoading, checkLoggedIn, stepForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchProgramForSelect(authState, []).then((res) => {
        if (isMounted) {
          setProgramList(res.data);
          dispatch(listActions.makePgList(res.data));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (programList.length == 0) {
      if (pgListState.select_list.length == 0) {
        fetchData();
      } else {
        setProgramList(pgListState.select_list);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, programList.length, pgListState.select_list]);

  useEffect(() => {
    setSelectList(selectedList.map(x => ({
      reflection: programList
        .filter(pg => pg.step == x.step)
        .map(pg => reflectionList.filter(y => pg.reflections.filter(rf => rf.reflection == y.value).length > 0)[0])
        .filter((pg, i, self) => self.indexOf(pg) === i),
      program: programList
        .filter(pg => pg.step == x.step && pg.reflections.filter(rf => rf.reflection == x.reflection).length > 0)
        .map(pg => ({ value: pg.id, label: pg.title }))
    })));
  }, [selectedList, programList, reflectionList])

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationTrainingH003Model(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  useEffect(() => {
    const time_selects = data.program_selects.filter(x => x.played).reduce((total, x) => total + parseInt(x.time === "" || x.time === undefined || x.time === null ? "0" : x.time, 10), 0);
    const time_adds = data.program_adds.reduce((total, x) => total + parseInt(x.time === "" || x.time === undefined || x.time === null ? "0" : x.time, 10), 0);
    setTimeTotal(time_selects + time_adds);
  }, [data]);

  useEffect(() => {
    const cautionedData = cautionTrainingH003Model(originalData,data);
    if (cautionedData.ok) {
      setCautionError(initialCautionError());
      setModeResend(false);
    } else {
      if (trainingStatus) {
        setCautionError(cautionedData.cautionError);
        setModeResend(true);
      }
    }
  }, [originalData, data, trainingStatus]);

  const setStepValue = (val: number, index: number) => {
    let newSelectedList = [...selectedList];
    newSelectedList[index].step = val;
    setSelectedList(newSelectedList);
    clearSelectProgram(index);
  };

  const setReflectValue = (val: number, index: number) => {
    let newSelectedList = [...selectedList];
    newSelectedList[index].reflection = val;
    setSelectedList(newSelectedList);
    clearSelectProgram(index);
  };

  const clearSelectProgram = (index: number) => {
    let dataPrev = { ...data };
    dataPrev.program_adds[index].program_item.id = 0;
    dataPrev.program_adds[index].program_item.video_url = undefined;
    dataPrev.program_adds[index].program_item.training_tools = [];
    setData(dataPrev);
  };

  const setProgramValue = (val: number, index: number) => {
    let dataPrev = { ...data };
    dataPrev.program_adds[index].program_item.id = val;
    const program = programList.filter(x => x.id == val)[0];
    dataPrev.program_adds[index].program_item.video_url = program.video_url;
    dataPrev.program_adds[index].program_item.training_tools = program.training_tools;
    dataPrev.program_adds[index].program_item.reflections = program.reflections;
    setData(dataPrev);
  };

  const getSelectValue = (val: number | undefined, list: GeneralForListSchema[]) => {
    if (val == undefined || val == 0) return null;
    const filteredList = list.filter(x => x && x.value == val);
    if (filteredList.length === 0) return null;
    return filteredList[0];
  };

  const setTemperature = (value: any) => {
    let dataPrev = { ...data };
    dataPrev.temperature = value;
    setData(dataPrev);
  };

  const clickCheck = (index: number) => {
    let dataPrev = { ...data };
    dataPrev.program_selects[index].played = !dataPrev.program_selects[index].played;
    setData(dataPrev);
  };

  const setSelectedTime = (index: number, value: any) => {
    let dataPrev = { ...data };
    dataPrev.program_selects[index].time = value;
    setData(dataPrev);
  };

  const setSelectedRemarks = (index: number, value: any) => {
    let dataPrev = { ...data };
    dataPrev.program_selects[index].remarks = value;
    setData(dataPrev);
  };


  const setAddTime = (index: number, value: any) => {
    let dataPrev = { ...data };
    dataPrev.program_adds[index].time = value;
    setData(dataPrev);
  };

  const clickAddRow = () => {
    let dataPrev = { ...data };
    dataPrev.program_adds.push(initialProgramSelectModel());
    setData(dataPrev);
    setSelectedList([...selectedList, initialSelected()]);
  };

  const deleteItem = (index: number) => {
    let newSelectedList = [...selectedList];
    newSelectedList.splice(index, 1);
    setSelectedList(newSelectedList);
    let newData = { ...data };
    newData.program_adds.splice(index, 1);
    setData(newData);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const newData = { ...data };
    const [movedItem] = newData.program_adds.splice(result.source.index, 1);
    newData.program_adds.splice(result.destination.index, 0, movedItem);
    setData(newData);
    // 差異が出ないよう、元データも順序を反映
    let newOriginalData = { ...originalData };
    let [movedOriginalItem] = newOriginalData.program_adds.splice(result.source.index, 1);
    newOriginalData.program_adds.splice(result.destination.index, 0, movedOriginalItem);
    setOriginalData(newOriginalData);
  };

  const clickRegister = () => {
    const validatedData = validationTrainingH003Model(data);
    if (validatedData.ok) {
      changeIsLoading();
      putH003(authState, data).then((_) => {
        setShowRegister(false);
        notify("トレーニング結果を登録しました。");
        changeIsLoading();
        // 馬場さん希望で、登録後の画面遷移無し
        setReload(true);
      }).catch((err) => {
        checkLoggedIn(err);
        setShowRegister(false);
        notifyError("トレーニング結果の登録に失敗しました。実施日時の重複がないことを確認し、再度実行願います。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteTraining(authState, trainingId).then((_) => {
      setShowDelete(false);
      notify("トレーニング情報を削除しました。");
      changeIsLoading();
      history.goBack();
      if (from == "H002") history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const clickReferencing = () => {
    history.push(getForHistoryPush(H002Url, [0, data.id, "H003"]));
  };

  const clickSendRegister = () => {
    const validatedData = validationTrainingH003Model(data);
    if (validatedData.ok) {
      changeIsLoading();
      putNSendH003(authState, data).then((_) => {
        setShowSendRegister(false);
        setShowResendRegister(false);
        notify("トレーニング結果を保護者に送信しました。");
        changeIsLoading();
        // 馬場さん希望で、登録後の画面遷移無し
        setReload(true);
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowSendRegister(false);
      setShowResendRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickResend = () => {
    changeIsLoading();
    sendH003(authState, data).then((_) => {
      setShowResend(false);
      notify("トレーニング結果を保護者に再送信しました。");
      changeIsLoading();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const getSelectedReflectionName = (item: number) => {
    let name = "";
    if (reflectionList.length !== 0) {
      name = reflectionList.filter((x) => x.value == item)[0].label;
    }
    return name;
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="トレーニング結果" />
      <div className="plate">
          <>
            <ChildInfoTop
              user_type={authState.userInfo?.user_type}
              profile_pic_url={data.child?.profile_pic_url}
              id={data.child_id}
              name={(data.child?.family_name || "") + (data.child?.given_name || "")}
              name_kana={(data.child?.family_name_kana || "") + (data.child?.given_name_kana || "")}
              facility_name={data.facility_name}
              container={!noInput ? `${getFormatDate(data.training_date)}実施` : `${getFormatDate(data.training_date)}`}
            />
            {noInput ?
              <CustomRow title="出欠タイプ" content={
                <Label value={getSelectValue(data.service_provision_status || 0, serviceProvisionStatusForSelect)?.label} />
              } />
              :
              <>
                <CustomRow title="実施時間" required={true} error_msg={validationError.messageOf.start_time} content={
                  <>
                    <CustomTime val={data.start_time} setter={(v: string) => setData({ ...data, start_time: v })} />
                    <div className="wave_str">～</div>
                    <CustomTime val={data.end_time} setter={(v: string) => setData({ ...data, end_time: v })} />
                  </>
                } caution_msg={cautionError.messageOf.start_time}/>
                <CustomRow title="出欠タイプ" required={true} error_msg={validationError.messageOf.service_provision_status} content={
                  <Select
                    value={getSelectValue(data.service_provision_status || 0, serviceProvisionStatusForSelect)}
                    options={serviceProvisionStatusForSelect}
                    className="H002_select mx-2"
                    onChange={(e: any) => setData({ ...data, service_provision_status: e.value })}
                    placeholder="選択"
                  />
                } />
              </>
            }
            {data.service_provision_status == SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY &&
              <CustomRow title="家族支援加算" content={
                <Select
                  value={getSelectValue(data.consultation_support_addition, consultationSupportAdditionForSelect)}
                  options={consultationSupportAdditionForSelect}
                  className="H003_select mx-2"
                  onChange={(e: any) => setData({ ...data, consultation_support_addition: e.value })}
                  placeholder="選択"
                />
              } caution_msg={cautionError.messageOf.consultation_support_addition}/>
            }

            {!noInput &&
              <>
                <CustomRow title="担当者" required={true} error_msg={validationError.messageOf.staff_id} content={
                  <>
                    <Select
                      value={getSelectValue(data.staff_id, staffForSelect)}
                      options={staffForSelect}
                      className="H003_select mx-2"
                      onChange={(e: any) => setData({ ...data, staff_id: e.value })}
                      placeholder="主担当者"
                    />
                    <Select
                      value={getSelectValue(data.staff2_id, staffForSelect)}
                      options={staffForSelect}
                      className="H003_select mx-2"
                      onChange={(e: any) => setData({ ...data, staff2_id: e.value })}
                      placeholder="副担当者"
                    />
                  </>
                  } caution_msg={cautionError.messageOf.staff_id}
                />
                <Label className="item fs-4" value={`計：${timeTotal}分`} />
                <Label className="item" value="実施した計画を選択" />
                {validationError.messageOf.program_selects ? <ErrorMsg error_msg = {validationError.messageOf.program_selects} /> : <CautionMsg caution_msg={cautionError.messageOf.program_selects} />}
                {data.program_selects.map((x, i) =>
                  <div key={i} className="item on_same_row">
                    <CheckBox checked={x.played} onChange={() => clickCheck(i)} />
                    <Label value={x.program_item.title} />
                    <NumBox value={x.time} onChange={(value: any) => setSelectedTime(i, value)} />
                    <Form.Label>分</Form.Label>
                    <TextBox
                      type="text"
                      placeholder="未実施理由など"
                      value={x.remarks}
                      onChange={(value: any) => setSelectedRemarks(i, value)}
                      maxLength={100}
                    />
                    <Play video_url={x.program_item.video_url} video_preparing={x.program_item.video_preparing} />
                  </div>
                )}
                <Label className="item" value="その他追加" />
                <div className="mx-2 mt-2 on_same_row" style={{ flexWrap: "wrap" }}>
                  {reflectionList.length > 0 && data.child?.reflections.map((x, i) => (
                    <div className="mb-2" key={i}>
                      <CustomButton
                        label={getSelectedReflectionName(x.item)}
                        class={[CB_CLASS.BLUE]}
                        size={CB_SIZE.S}
                        disabled={true}
                      />
                    </div>
                  ))}
                </div>
                {validationError.messageOf.program_adds ? <ErrorMsg error_msg = {validationError.messageOf.program_adds} /> : <CautionMsg caution_msg={cautionError.messageOf.program_adds} />}
                <DraggableList onDragEnd={handleDragEnd} items={
                  data.program_adds.map((x: ProgramSelectSchema, i: number) => (
                    {
                      id: i, content: (
                        <div key={i} className="item on_same_row align-items-center">
                          <Select
                            value={getSelectValue(selectedList.length > i ? selectedList[i].step : undefined, stepForSelect)}
                            options={stepForSelect}
                            className="H002_select"
                            onChange={(e: any) => setStepValue(e.value, i)}
                            placeholder="Step"
                          />
                          {selectList[i]?.reflection.length > 0 &&
                            <Select
                              value={getSelectValue(selectedList.length > i ? selectedList[i].reflection : undefined, selectList[i].reflection)}
                              options={selectList[i].reflection}
                              className="H002_select mx-2"
                              onChange={(e: any) => setReflectValue(e.value, i)}
                              placeholder="反射"
                            />
                          }
                          {selectList[i]?.program.length > 0 &&
                            <Select
                              value={getSelectValue(x.program_item?.id ?? 0, selectList[i].program)}
                              options={selectList[i].program}
                              className="H002_program_select"
                              onChange={(e: any) => setProgramValue(e.value, i)}
                              placeholder="プログラム"
                            />
                          }
                          {(x.program_item?.id ?? 0) > 0 &&
                            <FormLabel className="mx-2">
                              {x.program_item.reflections.length > 0 &&
                                `${x.program_item.reflections.map((y: ReflectionSchema) =>
                                  getSelectValue(y.reflection, reflectionList)?.label[0]
                                ).join("、")}`}
                            </FormLabel>
                          }
                          <NumBox value={x.time} onChange={(value: any) => setAddTime(i, value)} />
                          <Form.Label>分</Form.Label>
                          <Play video_url={x.program_item.video_url} video_preparing={x.program_item.video_preparing} />
                          <Delete func={() => { deleteItem(i) }} />
                        </div>
                      )
                    }
                  ))
                } />
                <CustomButton label="＋行を追加" class={[CB_CLASS.RED]} align={CB_ALIGN.LEFT} onClick={clickAddRow} />
                <div className="mt-2 custom_row row"></div>
                <CustomRow title="気づきなど" error_msg={validationError.messageOf.remarks} content={
                  <div style={{ width: "100%" }}>
                    <CustomTextarea
                      maxRows={18}
                      placeholder="気づきなど　※保護者には送信されません。200文字程度を目安に（上限500文字）"
                      value={data.remarks}
                      onChange={(v: string) => {
                        setData({ ...data, remarks: v });
                        setRemarksLength(v.length);
                      }}
                    />
                    <div>
                      <Label value={`（${remarksLength}/500）文字数　※200文字程度で書いてください`} />
                    </div>
                  </div>
                } />
                <CustomRow title="保護者へのメッセージ" error_msg={validationError.messageOf.message} content={
                  <div style={{ width: "100%" }}>
                    <CustomTextarea
                      maxRows={6}
                      placeholder="保護者へのメッセージ　※200文字程度を目安に（上限250文字）"
                      value={data.message}
                      onChange={(v: string) => {
                        setData({ ...data, message: v });
                        setMessageLength(v.length);
                      }}
                    />
                    <div>
                      <Label value={`（${messageLength}/250）文字数　※200文字程度で書いてください`} />
                    </div>
                  </div>
                } caution_msg={cautionError.messageOf.message} />
                <CustomRow title="体温" content={
                  <>
                    <NumBox value={data.temperature} type={NUM_BOX_TYPE.TEMPERATURE} width={100} onChange={setTemperature} />
                    <Label className="item" value="℃" />
                  </>
                } />
                {data.support_type == DISABILITY_CERTIFICATE_SUPPORT_TYPE.DAY_SERVICE &&
                  <CustomRow title="提供形態" required={true} error_msg={validationError.messageOf.provided_form} content={
                    <Select
                      value={getSelectValue(data.provided_form, providedFormForSelect)}
                      options={providedFormForSelect}
                      className="H003_select mx-2"
                      onChange={(e: any) => setData({ ...data, provided_form: e.value })}
                      placeholder="提供形態"
                    />
                  } />
                }
                <CustomRow title="家族支援加算" content={
                  <Select
                    value={getSelectValue(data.consultation_support_addition, consultationSupportAdditionForSelect)}
                    options={consultationSupportAdditionForSelect}
                    className="H003_select mx-2"
                    onChange={(e: any) => setData({ ...data, consultation_support_addition: e.value == 0 ? undefined : e.value })}
                    placeholder="選択"
                  />
                } caution_msg={cautionError.messageOf.consultation_support_addition}/>
                <CustomRow title="専門的支援実施加算" content={
                  <Select
                    value={getSelectValue(data.specialist_support_addition, specialistSupportAdditionForSelect)}
                    options={specialistSupportAdditionForSelect}
                    className="H003_select mx-2"
                    onChange={(e: any) => setData({ ...data, specialist_support_addition: e.value == 0 ? undefined : e.value })}
                    placeholder="選択"
                  />
                } caution_msg={cautionError.messageOf.specialist_support_addition}/>
                <CustomRow title="子育てサポート加算" content={
                  <Select
                    value={getSelectValue(data.childcare_support_addition, childcareSupportAdditionForSelect)}
                    options={childcareSupportAdditionForSelect}
                    className="H003_select mx-2"
                    onChange={(e: any) => setData({ ...data, childcare_support_addition: e.value == 0 ? undefined : e.value })}
                    placeholder="選択"
                  />
                } caution_msg={cautionError.messageOf.childcare_support_addition}/>
                <CustomRow title="サービス提供実績記録票備考" error_msg={validationError.messageOf.provision_record_sheet_remarks} content={
                  <CustomTextarea placeholder="サービス提供実績記録票備考" value={data.provision_record_sheet_remarks} onChange={(v: string) => setData({ ...data, provision_record_sheet_remarks: v })} />
                } />
              </>
            }
            <div className="item">
              {!noInput &&
                (trainingStatus && !modeResend) || (!trainingStatus) ? (
                  <>
                    {/* 登録のみを再利用 */}
                    <CustomButton label = {trainingStatus && !modeResend ? "登録" : "登録のみ"} class={[CB_CLASS.RED]} min_width={250} onClick={() => setShowRegister(true)} />
                    <ConfirmModal target = {trainingStatus && !modeResend ? "登録" : "登録のみ"} show={showRegister} setShow={setShowRegister} func={clickRegister}
                      confirmText={"登録してよろしいでしょうか。"}
                    />
                  </>
                ) : null
              }
              {trainingStatus &&
                <>
                  {modeResend &&
                    <>
                      <CustomButton label="登録して保護者に再送信" class={[CB_CLASS.RED]} min_width={250} onClick={() => setShowResendRegister(true)} />
                      <ConfirmModal target="登録して保護者に再送信" show={showResendRegister} setShow={setShowResendRegister} func={clickSendRegister}
                        confirmText={"登録して保護者への再送信を実行してよろしいでしょうか。"}
                      />
                    </>
                  }
                  {!noInput &&
                    <>
                      <CustomButton label="監査ドキュメントへ" class={[CB_CLASS.BLUE]} min_width={250} onClick={() => history.push(getForHistoryPush(M002Url, [data.audit_doc_id]))} />
                    </>
                  }
                  {(!modeResend && data.status !== TRAINING_STATUS_SIGNED) &&
                    <>
                      <CustomButton label="保護者に再送信" class={[CB_CLASS.BLUE]} min_width={250} onClick={() => setShowResend(true)} disabled={data.status === TRAINING_STATUS_SIGNED ? true : false} />
                      <ConfirmModal target="保護者に再送信" show={showResend} setShow={setShowResend} func={clickResend}
                        confirmText={"保護者への再送信を実行してよろしいでしょうか。"}
                      />
                    </>
                  }
                </>
              }
              {!trainingStatus &&
                <>
                  <CustomButton label="登録して保護者に送信" class={[CB_CLASS.BLUE]} min_width={250} onClick={() => setShowSendRegister(true)} />
                  <ConfirmModal target="登録して保護者に送信" show={showSendRegister} setShow={setShowSendRegister} func={clickSendRegister}
                    confirmText={"「登録して保護者に送信」を実行してよろしいでしょうか。"}
                  />
                </>
              }
              {!noInput &&
                <>
                  <CustomButton label="引用してトレーニングを作成" class={[CB_CLASS.BLUE]} min_width={250} onClick={() => setShowReferencing(true)} />
                  <ConfirmModal target="引用してトレーニングを作成" show={showReferencing} setShow={setShowReferencing} func={clickReferencing}
                    confirmText={"「引用してトレーニングを作成」を実行してよろしいでしょうか。"}
                    />
                </>
              }
              {!trainingStatus &&
                <>
                  <CustomButton label="削除" class={[CB_CLASS.RED]} min_width={250} onClick={() => setShowDelete(true)} />
                  <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
                    confirmText={"削除してよろしいでしょうか。※予約枠に空きが出来ます。"}
                  />
                </>
              }
            </div>
          </>
      </div>
    </>
  );
};

export default H003;
