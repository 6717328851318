import React, { useEffect, useState } from "react";
import { addWeeks, format, subWeeks } from "date-fns";
import "./Weekly.scss";
import { FLG_HOLIDAY, FLG_WEEKDAY, initialK001WeeklyModel, makeFrameList, DISPLAY_FRAME_INDEX, isWithinRangeTheTime } from "../../models/schedule.model";
import { D010Url, H002Url, H003Url, K005Url, getForHistoryPush } from "../../helper/url.helper";
import { RESERVATION_FRAME_NOTIFIED_STATUS, SCHEDULE_DETAIL_STATUS } from "../../models/scheduleReservationFrame.model";
import { GeneralForListSchema, K001WeeklyDetailSchema, K001WeeklySchema } from "../../generated";
import { useHistory } from "react-router";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { DAYS_OF_WEEK, DEFAULT_TRAINING_MINUTE } from "../../constants";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { notifyError } from "../../helper/settings.helper";
import { useAuth } from "../../helper/auth.helper";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { addSlotK001Weekly, fetchK001Weekly, fetchStaffById, putK001WeeklyPic, reduceSlotK001Weekly } from "../../services/api.service";
import WeeklyNameButton, { SC_CLASS, WNB_CLASS } from "../../components/Button/WeeklyNameButton";
import Select from "../../components/Select/CustomSelect";
import CustomButton, { CB_ALIGN, CB_CLASS } from "../../components/Button/CustomButton";
import WeeklyAddSlotButton from "../../components/Button/WeeklyAddSlotButton";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { getLastDayOfTheWeek, getLastDayOfTheWeekStr } from "../../helper/date.helper";
import CustomModal from "../../components/CustomModal/CustomModal";
import DescribeWeekly from "./DescribeWeekly";
import { PiMouse } from "react-icons/pi";
import { addMinutes } from "../../helper/time.helper";
import { TRAINING_STATUS_NOT_PLANNED, TRAINING_STATUS_NOT_TRAINED } from "../../models/training.model";

interface CalendarProps {
  targetDate: Date;
  setTargetDate: React.Dispatch<React.SetStateAction<Date>>;
  getData: boolean;
  setGetData: React.Dispatch<React.SetStateAction<boolean>>;
  fcId: number;
}

const Weekly: React.VFC<CalendarProps> = ({
  targetDate,
  setTargetDate,
  getData,
  setGetData,
  fcId,
}) => {
  const history = useHistory();
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const [weeklySchedule, setWeeklySchedule] = useState<K001WeeklySchema>(initialK001WeeklyModel());
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [staffForSelect, setStaffForSelect] = useState<GeneralForListSchema[]>([]);
  const [selectStaff, setSelectStaff] = useState<number>(0);
  const [isHighlightEmptySlots, setIsHighlightEmptySlots] = useState<boolean>(false);
  const [isAddingEmptySlots, setIsAddingEmptySlots] = useState<boolean>(false);
  const [titleOftTheWeekly, setTitleOfTheWeekly] = useState<String>("");
  const frameListWeekDay = makeFrameList(weeklySchedule.weekday_training_start_min ? weeklySchedule.weekday_training_start_min : 45, FLG_WEEKDAY);
  const frameListHoliday = makeFrameList(weeklySchedule.holiday_training_start_min ? weeklySchedule.holiday_training_start_min : 0, FLG_HOLIDAY);
  const changeCurrentWeek = (pushMoveToNextWeek: boolean) => {
    pushMoveToNextWeek ? setTargetDate((current) => addWeeks(current, 1)) : setTargetDate((current) => subWeeks(current, 1));
    setGetData(true);
  };
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const timeFrames = {
    startOffsetMinutes: -10,
    endOffsetMinutes: 50
  };

  const getSelectValue = (val: number | undefined, list: GeneralForListSchema[]) => {
    if (val == 0 || val == undefined) return null;
    if (list.filter(x => x?.value ?? 0 == val).length > 0) {
      return list.filter(x => x.value == val)[0];
    }
  };

  useEffect(() => {
    if (fcId > 0) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        try {
          const [resStaff] = await Promise.all([
            fetchStaffById(authState, fcId),
          ]);
          if (isMounted) {
            setStaffForSelect([
              { value: 0, label: "　" },
              ...resStaff.data.map(x => ({
                value: x.id,
                label: x.family_name + x.given_name
              }))
            ]);
            changeIsLoading();
          }
        } catch (err) {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        }
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    } else {
      setStaffForSelect([]);
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId]);

  useEffect(() => {
    const fetchData = async () => {
      changeIsLoading();
      const startDayOfTheWeekStr = format(targetDate, "yyyy-MM-dd");
      const endDayOfTheWeek = getLastDayOfTheWeek(targetDate);
      const endDayOfTheWeekStr = getLastDayOfTheWeekStr(targetDate);
      if (targetDate.getMonth() == endDayOfTheWeek.getMonth()) {
        setTitleOfTheWeekly(format(targetDate, "y年M月"));
      } else {
        setTitleOfTheWeekly(format(targetDate, "y年M月") + "～" + (endDayOfTheWeek.getMonth() + 1) + "月");
      }
      fetchK001Weekly(authState, fcId, startDayOfTheWeekStr, endDayOfTheWeekStr).then((res) => {
        if (!res.data.weekday_training_start_min) res.data.weekday_training_start_min = 45;
        setWeeklySchedule(res.data);
        setGetData(false);
        changeIsLoading();
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("データの取得に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    };
    if (getData) {
      fetchData();
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, getData, targetDate, setGetData]);

  const choiceButtonColor = (detail: K001WeeklyDetailSchema) => {
    if (detail.frame_status == SCHEDULE_DETAIL_STATUS.APPROVED && detail.support_type == 2) return WNB_CLASS.BLUE;
    if (detail.frame_status == SCHEDULE_DETAIL_STATUS.APPROVED && detail.support_type == 1) return WNB_CLASS.DARK_PINK;
    if (detail.frame_status == SCHEDULE_DETAIL_STATUS.RESERVED) return WNB_CLASS.NONE;
    if (detail.frame_status == SCHEDULE_DETAIL_STATUS.TEMPORARY) return WNB_CLASS.GRAY;
    if ((!detail.frame_status) && (!detail.frame_notified_status)) return WNB_CLASS.LIGHT_PINK;
    if ((!detail.frame_status) && (isHighlightEmptySlots)) return WNB_CLASS.RED;
    if ((!detail.frame_status) && (detail.frame_notified_status == RESERVATION_FRAME_NOTIFIED_STATUS.NOT_NOTIFIED)) return WNB_CLASS.YELLOW;
    return WNB_CLASS.NONE;
  }

  const onChangeStaff = (trainingId: number | undefined, staffId: number | undefined) => {
    if (!trainingId || !staffId) return;
    putK001WeeklyPic(authState, trainingId, staffId).then((_) => {
      setGetData(true);
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
    });
  };

  const addEmptySlot = (date: string, startTime: string) => {
    // startTimeから45分後を指定、srfがすでにある場合は枠を拡張
    const endTime = addMinutes(startTime, DEFAULT_TRAINING_MINUTE)
    addSlotK001Weekly(authState, fcId, date, startTime, endTime).then((_) => {
      setGetData(true);
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
    });
  };

  const deleteEmptySlot = (schedule_reservation_id: number | undefined) => {
    if ((!schedule_reservation_id)) return;
    reduceSlotK001Weekly(authState, schedule_reservation_id).then((_) => {
      setGetData(true);
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
    });
  };

  const choiceFunctionOnDoubleClick = (detail: K001WeeklyDetailSchema) => {
    const { training_id, training_status, frame_status, child_name, schedule_reservation_id, child_id } = detail;
    if (training_id && training_status) {
      const isNotTrainedOrPlanned = [TRAINING_STATUS_NOT_TRAINED, TRAINING_STATUS_NOT_PLANNED].includes(training_status);
      const url = isNotTrainedOrPlanned 
        ? getForHistoryPush(H002Url, [training_id, 0, "K001"]) 
        : getForHistoryPush(H003Url, [training_id, "K001"]);
      return history.push(url);
    }
    if (frame_status && [SCHEDULE_DETAIL_STATUS.RESERVED, SCHEDULE_DETAIL_STATUS.TEMPORARY].includes(frame_status)) {
      return history.push(getForHistoryPush(K005Url, [fcId, schedule_reservation_id]));
    }
    if (child_name && !schedule_reservation_id) {
      return history.push(getForHistoryPush(D010Url, [child_id]));
    }
    return deleteEmptySlot(schedule_reservation_id);
  };

  const frameListTime = (time: string, offsetMinutes: number): string => {
    const [hours, minutes] = time.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes + offsetMinutes;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")}`;
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div className="w_buttons">
        <div className="on_same_row">
          <MdNavigateBefore size="40px" className="move_calendar" onClick={() => {
            changeCurrentWeek(false);
          }} />
          <MdNavigateNext size="40px" className="move_calendar" onClick={() => {
            changeCurrentWeek(true);
          }
          } />
          <h1 className="title-year-month">{titleOftTheWeekly}</h1>
          <IoIosHelpCircleOutline size="40px" className="icon_pointer mx-2" onClick={() => setShowHelp((prevShow) => !prevShow)}/>
          <CustomModal show={showHelp} setShow={setShowHelp} size="lg"
            headerContents={
              <div className="help_header"><PiMouse size="24px" color="#ff8484"/>：ダブルクリック<PiMouse size="24px" color="#8484ff" className="ms-2"/>：クリック</div>
            }
            contents={
            <DescribeWeekly />
            }
          />
        </div>
        <div className="on_same_row">
          <CustomButton label={"予約空き枠"} class={isHighlightEmptySlots ? [CB_CLASS.RED] : [CB_CLASS.RED_SHADOW]} align={CB_ALIGN.CENTER} onClick={() => setIsHighlightEmptySlots(!isHighlightEmptySlots)} />
          <CustomButton label={"予約空き枠追加"} class={isAddingEmptySlots ? [CB_CLASS.BLUE] : [CB_CLASS.LIGHT_GRAY]} align={CB_ALIGN.CENTER} onClick={() => setIsAddingEmptySlots(!isAddingEmptySlots)} />
          <Select
            value={getSelectValue(selectStaff || 0, staffForSelect)}
            options={staffForSelect}
            className="H002_select mx-2"
            onChange={(e: any) => setSelectStaff(e.value)}
            placeholder="担当"
          />
        </div>
      </div>
      <div className="w_table_area">
        <table className="w_table">
          <thead>
            <tr className="r_weekday">
              <th className="h_start_time_top weekday"></th>
              {weeklySchedule.weekly_schedule_weekday.map((days, index) => (
                <th key={index} className={weeklySchedule.holiday_plan.find((plan) => plan.date == days.date) ? "h_title holiday" : "h_title weekday"}>{new Date(days.date).getDate()}<br />{DAYS_OF_WEEK[new Date(days.date).getDay()]}</th>
              ))}
              <th className="h_start_time_top holiday"></th>
              {weeklySchedule.weekly_schedule_holiday.map((days, index) => (
                <th key={index} className={weeklySchedule.holiday_plan.find((plan) => plan.date == days.date) ? "h_title weekday" : "h_title holiday"}>{new Date(days.date).getDate()}<br />{DAYS_OF_WEEK[new Date(days.date).getDay()]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {DISPLAY_FRAME_INDEX.map((index, i) => (
              <tr key={i}>
                <td className="h_start_time weekday ">{frameListWeekDay[index]}</td>
                {weeklySchedule.weekly_schedule_weekday.map((days, j) => {
                  const irregularHoliday = weeklySchedule.holiday_plan.find((plan) => plan.date == days.date)
                  const frameList = irregularHoliday ? frameListHoliday : frameListWeekDay;
                  const startTime = frameListTime(frameList[index], timeFrames.startOffsetMinutes);
                  const endTime = frameListTime(frameList[index], timeFrames.endOffsetMinutes);

                  return <td key={j} className={irregularHoliday ? "b_frames holiday" : "b_frames weekday"} valign="top" align="center">
                    {(days.details.filter((detail) => (
                      isWithinRangeTheTime(startTime, endTime, detail.start_time)
                    )).filter((detail) => detail.frame_status !== SCHEDULE_DETAIL_STATUS.CANCELED)
                    ).map((detail, k) => {
                      return <WeeklyNameButton
                        key={days + "_" + detail.start_time + "_" + k}
                        childName={detail.child_name}
                        options={staffForSelect}
                        staff={detail.staff_id || 0}
                        color={choiceButtonColor(detail)}
                        staffColor={(detail.staff_id == selectStaff) ? SC_CLASS.YELLOW : SC_CLASS.NONE}
                        onChangeStaff={(e: any) => onChangeStaff(detail.training_id, e.value)}
                        onDoubleClick={() => choiceFunctionOnDoubleClick(detail)}
                      />
                    })}
                    {isAddingEmptySlots &&
                      <WeeklyAddSlotButton onClick={() => addEmptySlot(days.date, frameList[index])} />
                    }
                  </td>
                })}
                <td className="h_start_time holiday">{frameListHoliday[index]}</td>
                {weeklySchedule.weekly_schedule_holiday.map((days, j) => {
                  const irregularWeekday = weeklySchedule.holiday_plan.find((plan) => plan.date == days.date)
                  const frameList = irregularWeekday ? frameListWeekDay : frameListHoliday;
                  const startTime = frameListTime(frameList[index], timeFrames.startOffsetMinutes);
                  const endTime = frameListTime(frameList[index], timeFrames.endOffsetMinutes);

                  return <td key={j} className={irregularWeekday ? "b_frames weekday" : "b_frames holiday"} valign="top" align="center">
                    {(days.details.filter((detail) => (
                      isWithinRangeTheTime(startTime, endTime, detail.start_time)
                    )).filter((detail) => detail.frame_status !== SCHEDULE_DETAIL_STATUS.CANCELED)
                    ).map((detail, k) => {
                      return <WeeklyNameButton
                        key={days + "_" + detail.start_time + "_" + k}
                        childName={detail.child_name}
                        options={staffForSelect}
                        staff={detail.staff_id || 0}
                        color={choiceButtonColor(detail)}
                        staffColor={(detail.staff_id == selectStaff) ? SC_CLASS.YELLOW : SC_CLASS.NONE}
                        onChangeStaff={(e: any) => onChangeStaff(detail.training_id, e.value)}
                        onDoubleClick={() => choiceFunctionOnDoubleClick(detail)}
                      />
                    })}
                    {isAddingEmptySlots &&
                      <WeeklyAddSlotButton onClick={() => addEmptySlot(days.date, frameList[index])} />
                    }
                  </td>
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Weekly;