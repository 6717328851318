import "./M001.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  GeneralForListSchema,
  ChildSchema,
  AuditDocM001Schema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  fetchFcFacilities,
  fetchChildrenForSelectList,
  fetchGeneralsForListMulti,
  fetchM001,
  fetchM001Count,
  downloadM001,
  fetchM001DocksCount,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_ALIGN, CB_CLASS } from "../../components/Button/CustomButton"
import CustomDate from "../../components/Calendar/CustomDate"
import Title from "../../components/Title/Title"
import Chat from "../../components/Icon/Chat"
import User from "../../components/Pict/User"
import Status, { STATUS_RED, STATUS_BLUE, StatusSchema } from "../../components/Status/Status"
import { initialGeneralForList } from "../../models/general.model";
import {
  LIST_DOC_TYPE,
  LIST_AUDIT_DOC_STATUS,
  HEADQUARTER_USER,
  PARENT,
  LIST_AUDIT_DOC_DATE_TYPE,
} from "../../constants";
import { D002Url, D009Url, E002Url, G001Url, M002Url, getFileNameByUrl, getForHistoryPush } from "../../helper/url.helper";
import Download from "../../components/Icon/Download";
import ToolTip from "../../components/ToolTip/ToolTip";
import { AUDIT_DOC_STATUS, AUDIT_DOC_TYPE, AUDIT_DOC_DATE_TYPE } from "../../models/audit_doc.model";
import { useAuth } from "../../helper/auth.helper";
import moment from "moment";
import { notify, notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";
import ConfirmModal from "../../components/ConfirmModal";

interface iLocation {
  facility_id: number;
  doc_type: number;
  status: number;
}

const M001 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const location = useLocation<iLocation>();
  const [fcId, setFcId] = useState(location.state.facility_id);
  const authState = useSelector(authSelector);
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector);
  const [data, setData] = useState<AuditDocM001Schema[]>([]);
  const [getData, setGetData] = useState(true);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [childForSelect, setChildForSelect] = useState<GeneralForListSchema[]>([]);
  const [dateTypeSelect, setDateTypeSelect] = useState<GeneralForListSchema[]>([]);
  const [getChild, setGetChild] = useState(true);
  const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
  const [docTypeForSelect, setDocTypeForSelect] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [search, setSearch] = useState({
    child_id: 0,
    begin_date: location.state.status ? "" : moment().subtract(1, "months").format("yyyy-MM-DD"),
    end_date: "",
    doc_type: location.state.doc_type,
    status: location.state.status,
    date_type: AUDIT_DOC_DATE_TYPE.DAY_SERVICE_PROVISION,
  });
  const status_list: StatusSchema[] = [
    { num: AUDIT_DOC_STATUS.NOT_YET, class: STATUS_RED },
    { num: AUDIT_DOC_STATUS.DONE, class: STATUS_BLUE },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 10;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();
  const [showDownload, setShowDownload] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchM001Count(authState, fcId, search.child_id, search.begin_date, search.end_date, search.doc_type, search.status, search.date_type),
          fetchM001(authState, fcId, search.child_id, search.begin_date, search.end_date, search.doc_type, search.status, search.date_type, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, fcId, getData, currentPage]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      const keywords = [LIST_DOC_TYPE, LIST_AUDIT_DOC_STATUS, LIST_AUDIT_DOC_DATE_TYPE];
      fetchGeneralsForListMulti(authState, keywords).then(res => {
        if (isMounted) {
          const resGenData = res.data as any;
          resGenData[LIST_DOC_TYPE].unshift(initialGeneralForList());
          resGenData[LIST_AUDIT_DOC_STATUS].unshift(initialGeneralForList());
          setDocTypeForSelect(resGenData[LIST_DOC_TYPE]);
          setStatusForSelect(resGenData[LIST_AUDIT_DOC_STATUS]);
          setDateTypeSelect(resGenData[LIST_AUDIT_DOC_DATE_TYPE]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (statusForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, statusForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then(res => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchChildrenForSelectList(authState, fcId).then((res) => {
        if (isMounted) {
          const forSelect: GeneralForListSchema[] = [initialGeneralForList()];
          res.data.map((x: ChildSchema, i) => {
            forSelect.push({
              value: x.id,
              label: x.family_name + x.given_name
            });
          });
          setChildForSelect(forSelect);
        }
        changeIsLoading();
        setGetChild(false);
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcId > 0 && getChild) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, getChild]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const getMoveTo = (v: AuditDocM001Schema) => {
    switch (v.doc_type) {
      case AUDIT_DOC_TYPE.ASSESSMENT_SHEET:
        history.push(getForHistoryPush(D009Url, [v.assessment_id, v.child_id]));
        break;
      case AUDIT_DOC_TYPE.SERVICE_REPRESENTATIVE_MTG_RECORDS:
      case AUDIT_DOC_TYPE.INDIVIDUAL_SUPPORT_PLAN:
      case AUDIT_DOC_TYPE.MONITORING_RECORDS:
      case AUDIT_DOC_TYPE.INDIVIDUAL_SUPPORT_PLAN_IDEA:
        history.push(getForHistoryPush(E002Url, [v.individual_support_plan_id, v.child_id]));
        break;
      default:
        history.push(getForHistoryPush(M002Url, [v.id]));
        break;
    }
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  const clickDownload = async () => {
    changeIsLoading();
    try {
      const resCount = await fetchM001DocksCount(authState, fcId, search.child_id, search.begin_date, search.end_date, search.doc_type, search.date_type);
      if (resCount.data == 0) {
        notify("対象帳票が存在しません。");
        return;
      }
      const res = await downloadM001(authState, fcId, search.child_id, search.begin_date, search.end_date, search.doc_type, search.date_type);
      if (res.status === 200) {
        const blob = res.data as Blob;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "監査ドキュメントダウンロード.zip";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        notifyError("ダウンロードに失敗しました。");
      }
    } catch (e) {
      checkLoggedIn(e);
      notifyError("ダウンロードに失敗しました。");
      console.error(e);
    } finally {
      setShowDownload(false);
      changeIsLoading();
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="監査ドキュメント一覧" />
      <div className="plate">
        <div className="on_same_row" style={{ maxWidth: maxWidth }}>
          {userInfo?.user_type == HEADQUARTER_USER &&
            <div className="item">
              <Select
                value={getSelectValue(fcId, fcForSelect)}
                options={fcForSelect}
                className="M001_select"
                onChange={(e: any) => {
                  setSearch({ ...search, child_id: 0 });
                  setFcId(e.value);
                  setGetChild(true);
                  setGetData(true);
                }}
                placeholder="施設"
              />
            </div>
          }
          {fcId > 0 &&
            <div className="item">
              <Select
                value={getSelectValue(search.child_id, childForSelect)}
                options={childForSelect}
                className="M001_select"
                onChange={(e: any) => {
                  setSearch({ ...search, child_id: e.value });
                  setGetData(true);
                }}
                placeholder="お子様"
              />
            </div>
          }
            <div className="item">
              <Select
                value={getSelectValue(search.date_type, dateTypeSelect)}
                options={dateTypeSelect}
                className="M001_select"
                onChange={(e: any) => {
                  setSearch({ ...search, date_type: e.value });
                  setGetData(true);
                }}
                placeholder="日付の種類"
              />
            </div>
          <div className="on_same_row item">
            <CustomDate val={search.begin_date} setter={(v: string) => {
              setSearch({ ...search, begin_date: v });
              setGetData(true);
            }} />
            <div className="wave_str">～</div>
            <CustomDate val={search.end_date} setter={(v: string) => {
              setSearch({ ...search, end_date: v });
              setGetData(true);
            }} />
          </div>
        </div>
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            <div className="item">
              <Select
                value={getSelectValue(search.doc_type, docTypeForSelect)}
                options={docTypeForSelect}
                className="M001_select"
                onChange={(e: any) => {
                  setSearch({ ...search, doc_type: e.value });
                  setGetData(true);
                }}
                placeholder="帳票種別"
              />
            </div>
            <div className="item">
              <Select
                value={getSelectValue(search.status, statusForSelect)}
                options={statusForSelect}
                className="M001_select"
                onChange={(e: any) => {
                  setSearch({ ...search, status: e.value });
                  setGetData(true);
                }}
                placeholder="登録状況"
              />
            </div>
          </div>
          <div className="on_same_row">
            <CustomButton
              label="一括ダウンロード"
              min_width={180}
              class={[CB_CLASS.BLUE]}
              onClick={() => setShowDownload(true)}
            />
            <ConfirmModal target="登録" show={showDownload} setShow={setShowDownload} func={clickDownload}
              confirmText={"一括ダウンロードを実行しますか？"}
            />
            <CustomButton
              label="検索条件クリア"
              min_width={180}
              class={[CB_CLASS.BLUE]}
              onClick={() => {
                setFcId(location.state.facility_id);
                setGetChild(true);
                setSearch({
                  child_id: 0,
                  date_type: AUDIT_DOC_DATE_TYPE.DAY_SERVICE_PROVISION,
                  begin_date: "",
                  end_date: "",
                  doc_type: 0,
                  status: 0
                });
                setGetData(true);
              }}
            />
            <CustomButton
              label="＋監査ドキュメントを追加"
              min_width={200}
              class={[CB_CLASS.RED]}
              onClick={() => history.push(getForHistoryPush(M002Url, [0]))}
            />
          </div>
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        max_width={maxWidth}
        headers={userInfo?.user_type == HEADQUARTER_USER
          ? ["施設", "帳票種別", "お子様", "登録日", "登録状況", ""]
          : ["帳票種別", "お子様", "登録日", "登録状況", ""]
        }
        list={data.map((x, i) => (
          <tr key={i} onClick={() => getMoveTo(x)}>
            {userInfo?.user_type == HEADQUARTER_USER &&
              <td className="item hide_over" style={{ maxWidth: 200 }}>
                {x.facility_name}
              </td>
            }
            <td className="item">
              {docTypeForSelect.filter(y => y.value == x.doc_type)[0]?.label}
            </td>
            <td className="item" valign="middle" align="center">
              {x.child_id &&
                <div className="on_same_row justify_center">
                  <User
                    url={x.child_profile_pic_url}
                    func={() => history.push(getForHistoryPush(D002Url, [x.child_id]))}
                  />
                  <div className="item">
                    {x.child_name}
                  </div>
                  <Chat user_type={userInfo?.user_type} func={() => history.push(getForHistoryPush(G001Url, [x.parent_id, PARENT]))} />
                </div>
              }
            </td>
            <td className="item">
              {x.make_date}
            </td>
            <td className="item" valign="middle" align="center">
              <Status
                label_list={statusForSelect}
                color_list={status_list}
                status={x.status}
                max_width={50}
              />
            </td>
            <td className="item" valign="middle" align="center">
              {(x.status == AUDIT_DOC_STATUS.DONE && x.file_url) &&
                <ToolTip text={getFileNameByUrl(x.file_url)} content={
                  <Download url={String(x.file_url)} />
                } />
              }
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default M001;
