import { validationMessage } from "../config/validationMessage";
import { P002Schema, ProgramSelectSchema, TrainingH002Schema, TrainingH003Schema } from "../generated";
import { checkFlgObj, checkSelectOrRadio, checkValueNotRequire } from "../helper/validation.helper";
import { DISABILITY_CERTIFICATE_SUPPORT_TYPE } from "./child.model";
import { ValidatedData, initialValidationError } from "./validate.model";
import { CautionedData, initialCautionError } from "./caute.model";
import { format } from "date-fns-tz";

/*
トレーニング実施状況
*/
export const TRAINING_STATUS_NOT_TRAINED = 1;      // 未実施
export const TRAINING_STATUS_TRAINED = 2;          // 仮保存
export const TRAINING_STATUS_SENT = 3;             // 送信済
export const TRAINING_STATUS_SIGNED = 4;           // サイン済
export const TRAINING_STATUS_NOT_PLANNED = 5;      // 未計画

export const SERVICE_PROVISION_STATUS = {
  ABSENCE: 1,                                      // 欠席
  ABSENCE_1: 2,                                    // 欠席Ⅰ（廃止）
  ABSENCE_2: 3,                                    // 欠席Ⅱ（廃止）
  ATTENDANCE: 4,                                   // 出席
  INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY: 5,   // 家族支援加算のみ
};

export const initialTrainingH002Model = (fcId: number): TrainingH002Schema => ({
  training_date: format(new Date, "yyyy-MM-dd"),
  start_time: "10:00",
  end_time: "10:45",
  status: TRAINING_STATUS_NOT_PLANNED,
  id: 0,
  facility_id: fcId,
  child_id: 0,
  staff_id: undefined,
  staff2_id: undefined,
  program_selects: [initialProgramSelectModel()],
  service_provision_status: SERVICE_PROVISION_STATUS.ATTENDANCE,
});

export const initialTrainingH003Model = (): TrainingH003Schema => ({
  training_date: format(new Date, "yyyy-MM-dd"),
  start_time: "10:00",
  end_time: "10:45",
  status: TRAINING_STATUS_NOT_TRAINED,
  id: 0,
  facility_id: 0,
  facility_name: "",
  child_id: 0,
  staff_id: 0,
  staff2_id: 0,
  program_selects: [initialProgramSelectModel()],
  program_adds: [],
  support_type: 0,
  service_provision_status: SERVICE_PROVISION_STATUS.ATTENDANCE,
});

export const initialProgramSelectModel = (): ProgramSelectSchema => ({
  time: undefined,
  played: false,
  program_item: initialP002Model(),
  remarks: "",
});

export const initialP002Model = (): P002Schema => ({
  title: "",
  step: 0,
  status: 0,
  training_tools: [],
  reflections: [],
  video_preparing: true,
});

export const validationTrainingH002Model = (
  data: TrainingH002Schema
): ValidatedData<TrainingH002Schema> => {
  const error = initialValidationError<TrainingH002Schema>();
  let ok = true;
  const noInputList = [
    SERVICE_PROVISION_STATUS.ABSENCE,
    SERVICE_PROVISION_STATUS.ABSENCE_1,
    SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY,
  ];

  if (data.facility_id === 0) {
    error.messageOf.facility_id = validationMessage.noEnteredTargetFacility;
    ok = false;
  }

  if (data.child_id === 0) {
    error.messageOf.child_id = validationMessage.noEnteredChild;
    ok = false;
  }

  if (!data.service_provision_status) {
    error.messageOf.service_provision_status = validationMessage.emptyTrainingAttendance;
    ok = false;
  }

  if (!data.training_date) {
    error.messageOf.training_date = validationMessage.emptyTrainingDate;
    ok = false;
  }

  if (noInputList.indexOf(data.service_provision_status || 0) == -1) {
    if (data.program_selects.filter(x => (x.program_item?.id ?? 0) > 0).length > 0 && !data.staff_id) {
      error.messageOf.staff_id = validationMessage.noEnteredStaff;
      ok = false;
    }
  }

  if (data.service_provision_status == SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY) {
    if (!data.consultation_support_addition) {
      error.messageOf.consultation_support_addition = validationMessage.noEnteredConsultationSupportAddition;
      ok = false;
    }
  }

  if (ok) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validationTrainingH003Model = (
  data: TrainingH003Schema
): ValidatedData<TrainingH003Schema> => {
  const error = initialValidationError<TrainingH003Schema>();
  let ok = true;
  const checkFlg: checkFlgObj = {
    isOk: true,
  };
  const noInputList = [
    SERVICE_PROVISION_STATUS.ABSENCE,
    SERVICE_PROVISION_STATUS.ABSENCE_1,
    SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY,
  ];

  if (data.facility_id === 0) {
    error.messageOf.facility_id = validationMessage.noEnteredTargetFacility;
    ok = false;
  };

  if (!data.service_provision_status) {
    error.messageOf.service_provision_status = validationMessage.emptyTrainingAttendance;
    ok = false;
  }

  if (!(data.start_time && data.end_time)) {
    error.messageOf.start_time = validationMessage.emptyTrainingTime;
    ok = false;
  };

  data.program_selects.map(x => {
    if (x.played && !x.time) {
      error.messageOf.program_selects = validationMessage.emptyTrainingMinute;
      ok = false;
    }
  });

  data.program_adds.map(x => {
    if (x.program_item.id && !x.time) {
      error.messageOf.program_adds = validationMessage.emptyTrainingMinute;
      ok = false;
    }
  });

  if (data.support_type == DISABILITY_CERTIFICATE_SUPPORT_TYPE.DAY_SERVICE
    && data.provided_form == undefined
    && [
      SERVICE_PROVISION_STATUS.ABSENCE,
      SERVICE_PROVISION_STATUS.ABSENCE_1,
      SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY
    ].indexOf(data.service_provision_status || 0) == -1) {
    error.messageOf.provided_form = validationMessage.noEnteredProvidedForm;
    ok = false;
  }

  if (noInputList.indexOf(data.service_provision_status || 0) == -1) {
    error.messageOf.remarks = checkValueNotRequire(checkFlg, "気づきなど", data.remarks, 500);
    error.messageOf.message = checkValueNotRequire(checkFlg, "保護者へのメッセージ", data.message, 250);
    error.messageOf.staff_id = checkSelectOrRadio(checkFlg, "主担当者", data.staff_id);
    error.messageOf.provision_record_sheet_remarks = checkValueNotRequire(checkFlg, "サービス提供実績記録票備考", data.provision_record_sheet_remarks, 20);
    if ((data.program_selects.filter(x => x.played).length + data.program_adds.filter(x => x.program_item.id && x.time).length) == 0) {
      checkFlg.isOk = false;
      error.messageOf.program_selects = validationMessage.noEnteredProgram;
    }
  }

  if (!checkFlg.isOk) ok = false;

  if (ok) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const cautionTrainingH003Model = (originalData: TrainingH003Schema, data: TrainingH003Schema): CautionedData<TrainingH003Schema> => {
  const caution = initialCautionError<TrainingH003Schema>();
  const msg = validationMessage.changeResend;
  let ok = true;

    if (originalData.staff_id != (data.staff_id == 0 ? null : data.staff_id) || originalData.staff2_id != (data.staff2_id == 0 ? null : data.staff2_id)) {
      caution.messageOf.staff_id = msg;
      ok = false;
    };

    if (originalData.start_time != data.start_time || originalData.end_time != data.end_time) {
      caution.messageOf.start_time = msg;
      ok = false;
    }

    data.program_selects.map((x, i) => {
      const originalProgramSelect = originalData.program_selects[i];
      if (originalProgramSelect) {
        if (originalProgramSelect.played != x.played || originalProgramSelect.time != x.time) {
          caution.messageOf.program_selects = msg;
          ok = false;
        }
      }
    });
    
    if (data.program_adds.length < originalData.program_adds.length) {
      caution.messageOf.program_adds = msg;
      ok = false;
    } else {
      data.program_adds.map((x, i) => {
        const originalProgramAdd = originalData.program_adds[i];
        if (originalProgramAdd) {
          if (originalProgramAdd.time != x.time || originalProgramAdd.program_item.id != x.program_item.id) {
            caution.messageOf.program_adds = msg;
            ok = false;
          }
        } else {
          if (x.program_item.id) {
            caution.messageOf.program_adds = msg;
            ok = false;
          }
        }
      });
    }

    if ((data.message ?? "") != (originalData.message ?? "")) {
      caution.messageOf.message = msg;
      ok = false;
    }

    if (data.consultation_support_addition != originalData.consultation_support_addition) {
      caution.messageOf.consultation_support_addition = msg;
      ok = false;
    }

    if (data.specialist_support_addition != originalData.specialist_support_addition) {
      caution.messageOf.specialist_support_addition = msg;
      ok = false;
    }

    if (data.childcare_support_addition != originalData.childcare_support_addition) {
      caution.messageOf.childcare_support_addition = msg;
      ok = false;
    }

  if (ok) {
    return {
      ok: true,
      cautionedData: data,
    };
  }

  return {
    ok: false,
    cautionError: caution,
  };
};